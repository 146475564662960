import React from 'react';
import { DetailsList, IColumn, SelectionMode, mergeStyles } from 'office-ui-fabric-react';
import { StatisticsInfo } from '../../store';
import { computeLoss } from '../../utils/task';

type IStatsDetailsProps = {
  fetchStats: StatisticsInfo;
  fileStats: StatisticsInfo;
};

type IItem = {
  field: string;
  inputValue: number;
  outputValue: number;
  decimals: number;
};

const valueClassName = mergeStyles({
  textAlign: 'right',
});

const columns: IColumn[] = [
  {
    key: 'field',
    fieldName: 'field',
    name: 'Показатель',
    minWidth: 100,
    maxWidth: 120,
  },
  {
    key: 'inputValue',
    name: 'Получено из Яндекс.Директа',
    minWidth: 100,
    maxWidth: 200,
    onRender: ({ inputValue, decimals }: IItem) => (
      <div className={valueClassName}>{inputValue.toFixed(decimals)}</div>
    ),
  },
  {
    key: 'outputValue',
    name: 'Выгружено в GA',
    minWidth: 100,
    maxWidth: 130,
    onRender: ({ outputValue, decimals }: IItem) => (
      <div className={valueClassName}>{outputValue.toFixed(decimals)}</div>
    ),
  },
  {
    key: 'loss',
    name: 'Потери',
    minWidth: 80,
    maxWidth: 100,
    onRender: (item: IItem) => {
      const [lossAmount, lossShare] = computeLoss(item.inputValue, item.outputValue);

      if (lossAmount > 0) {
        return (
          <div className={valueClassName}>{`-${lossAmount.toFixed(item.decimals)} (${(
            lossShare * 100
          ).toFixed()}%)`}</div>
        );
      }

      return <div className={valueClassName}>0</div>;
    },
  },
  {
    key: 'dummy',
    name: '',
    minWidth: 1,
  },
];

export const StatsDetails: React.FC<IStatsDetailsProps> = ({ fetchStats, fileStats }) => {
  const items = React.useMemo(() => {
    return [
      {
        field: 'Показы',
        inputValue: fetchStats.impressions,
        outputValue: fileStats.impressions,
        decimals: 0,
      },
      {
        field: 'Клики',
        inputValue: fetchStats.clicks,
        outputValue: fileStats.clicks,
        decimals: 0,
      },
      {
        field: 'Расход',
        inputValue: fetchStats.cost,
        outputValue: fileStats.cost,
        decimals: 2,
      },
    ];
  }, [fetchStats, fileStats]);

  return <DetailsList items={items} columns={columns} selectionMode={SelectionMode.none} />;
};

export default StatsDetails;
