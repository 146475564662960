import React from 'react';
import { Panel, PanelType, Separator } from 'office-ui-fabric-react';
import { LongDate } from '@airmedia/react-time';
import { IDataSet } from '../../store';
import PrepareState from '../TaskPanel/PrepareState';
import DescriptionList from '../DescriptionList';
import { translate as translateReportType } from '../../utils/reportType';
import ReportOptionsDescriptionList from '../ReportOptionsDescriptionList';

export interface IDataSetPanelProps {
  dataset: IDataSet;
  onClose: () => void;
}

export const DataSetPanel = ({ dataset, onClose }: IDataSetPanelProps) => {
  const handleDismiss = React.useCallback(() => onClose(), [onClose]);

  return (
    <Panel
      isOpen={true}
      isLightDismiss={true}
      type={PanelType.largeFixed}
      hasCloseButton={true}
      onDismiss={handleDismiss}
      headerText="Информация о наборе данных"
    >
      {dataset.isFetching && dataset.id === undefined && (
        <PrepareState isFetching={dataset.isFetching} />
      )}
      {dataset.id && (
        <>
          <DescriptionList>
            <dt>Название</dt>
            <dd>{dataset.name}</dd>
            <dt>Создан</dt>
            <dd>
              <LongDate date={dataset.createdAt} />
            </dd>
            <Separator>Настройки</Separator>
            <dt>Тип отчета</dt>
            <dd>{translateReportType(dataset.reportType)}</dd>
            {dataset.reportOptions !== undefined && (
              <ReportOptionsDescriptionList options={dataset.reportOptions} />
            )}
            {dataset.dataSource && (
              <>
                <Separator>Импорт данных в Google Analytics</Separator>
                <dt>Название</dt>
                <dd>{dataset.dataSource?.name}</dd>
                <dt>Идентификатор</dt>
                <dd>{dataset.dataSource?.id}</dd>
              </>
            )}
          </DescriptionList>
        </>
      )}
    </Panel>
  );
};

export default DataSetPanel;
