import { NormalizedSchema } from 'normalizr';

export enum IntegrationStatusEnum {
  CONNECTED = 'connected',
  DISABLED = 'disabled',
  PENDING = 'pending',
  REVOKED = 'revoked',
  UNCONNECED = 'unconnected',
}

export enum UserRoleEnum {
  ADMIN = 'ADMIN',
  ASSISTANT = 'ASSISTANT',
  CHIEF = 'CHIEF',
  OWNER = 'OWNER',
}

export interface IProjectItem {
  readonly id: string;
  readonly name: string;
  readonly archived: boolean;
  readonly createdAt: string;
}

export interface IProjectDetails extends IProjectItem {
  readonly yandexDirectAuthLogin: string | null;
  readonly yandexDirectClientLogin: string | null;
  readonly yandexDirectStatus: IntegrationStatusEnum;
  readonly googleAnalyticsAuthLogin: string | null;
  readonly googleAnalyticsAccountId: string | null;
  readonly googleAnalyticsWebPropertyId: string | null;
  readonly googleAnalyticsStatus: IntegrationStatusEnum;
  readonly userRole: UserRoleEnum;
}

type ArrayEntities = {
  projects: {
    [key: string]: IProjectItem;
  };
};

type SingleEntity = {
  projects: {
    [key: string]: IProjectDetails;
  };
};

export type IProjectListResponse = NormalizedSchema<ArrayEntities, string[]> & {
  meta: {
    total: number;
    limit: number;
    offset: number;
  };
};

export type IProjectDetailsResponse = NormalizedSchema<SingleEntity, string>;
