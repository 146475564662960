import format from 'date-fns/lightFormat';
import parse from 'date-fns/parse';

const backupDate = new Date(0);

export function parseYmd(date: string): Date {
  return parse(date, 'yyyy-MM-dd', backupDate);
}

export function formatYmd(date: Date): string {
  return format(date, 'yyyy-MM-dd');
}

export function formatLocaleDate(date: Date | string): string {
  return format(typeof date === 'string' ? parseYmd(date) : date, 'dd.MM.yyyy');
}
