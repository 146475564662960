import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReducer';
import { rootSaga } from './rootSagas';
import { composeEnhancers } from './utils';

export * from './datasets';
export * from './projects';
export * from './session';
export * from './tasks';
export * from './view';

const sagaMiddleware = createSagaMiddleware();

// configure middlewares
const middlewares = [sagaMiddleware];
// compose enhancers
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

// rehydrate state on app start
const initialState = {};

const store = createStore(rootReducer, initialState, enhancer);

sagaMiddleware.run(rootSaga);

export default store;
