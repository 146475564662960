import React from 'react';
import { Field } from 'formik';
import { FormikDropdown } from 'formik-office-ui-fabric-react';
import { getTheme, IDropdownOption, mergeStyleSets } from 'office-ui-fabric-react';
import { REPORT_SCHEMA } from '../../../constants/report';
import { ReportType } from '../../../store';
import { IDataSourceItem } from '../../../api';

const { semanticColors, fonts } = getTheme();

const descriptionClassNames = mergeStyleSets({
  root: {
    color: semanticColors.bodySubtext,
    fontSize: fonts.xSmall.fontSize,
  },
  snippet: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'auto',
    padding: '5px 0',
  },
});

export type IDataSourceFieldProps = {
  disabled?: boolean;
  dataSources: IDataSourceItem[];
  reportType: ReportType;
};

export const DataSourceField: React.FC<IDataSourceFieldProps> = ({
  disabled,
  dataSources,
  reportType,
}) => {
  const dataSourceOptions = React.useMemo(
    (): IDropdownOption[] =>
      dataSources.map(
        (item) =>
          ({
            key: item.id,
            text: item.name,
          } as IDropdownOption)
      ),
    [dataSources]
  );

  return (
    <>
      <Field
        name="dataSource"
        label="Набор данных в Google Analytics"
        placeholder="Выберите набор данных"
        required
        disabled={disabled}
        options={dataSourceOptions}
        component={FormikDropdown}
      />
      <div className={descriptionClassNames.root}>
        Схема источника данных должна быть совместима с указанной ниже:
        <code className={descriptionClassNames.snippet}>{REPORT_SCHEMA[reportType] || '—'}</code>
      </div>
    </>
  );
};

export default DataSourceField;
