import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { takeLeadingPerKey } from '../../utils/saga';
import * as api from '../../api';
import * as actions from './actions';
import * as types from './types';

type LoadDataSetListAction = ReturnType<typeof actions.fetchDataSetsAsync.request>;
type LoadDataSetAction = ReturnType<typeof actions.fetchDataSetAsync.request>;
type ArchiveDataSetAction = ReturnType<typeof actions.archiveDataSetAsync.request>;
type UnarchiveDataSetAction = ReturnType<typeof actions.unarchiveDataSetAsync.request>;

export function* loadDataSetList(action: LoadDataSetListAction): SagaIterator {
  try {
    // Fetch first 100 datasets
    const response = yield call(
      api.fetchDataSets,
      action.payload,
      100,
      0,
      api.CriteriaArchivedStateEnum.ALL
    );
    yield put(actions.fetchDataSetsAsync.success(response as api.IDataSetListResponse));
  } catch (e) {
    yield put(actions.fetchDataSetsAsync.failure(e));
  }
}

export function* loadDataSet(action: LoadDataSetAction): SagaIterator {
  const { projectId, datasetId } = action.payload;

  try {
    const response = yield call(api.fetchDataSetById, projectId, datasetId);
    yield put(
      actions.fetchDataSetAsync.success(response as api.IDataSetDetailsResponse, {
        projectId,
      })
    );
  } catch (e) {
    yield put(
      actions.fetchDataSetAsync.failure(e, {
        projectId,
        datasetId,
      })
    );
  }
}

export function* archiveDataSet(action: ArchiveDataSetAction): SagaIterator {
  const { projectId, datasetId } = action.payload;

  try {
    yield call(api.archiveDataSet, projectId, datasetId);
    yield put(
      actions.archiveDataSetAsync.success({
        projectId,
        datasetId,
      })
    );

    // Reload an entity to update permission flags
    yield put(actions.fetchDataSet(projectId, datasetId));
  } catch (e) {
    yield put(
      actions.archiveDataSetAsync.failure(e, {
        projectId,
        datasetId,
      })
    );
  }
}

export function* unarchiveDataSet(action: UnarchiveDataSetAction): SagaIterator {
  const { projectId, datasetId } = action.payload;

  try {
    yield call(api.unarchiveDataSet, projectId, datasetId);
    yield put(
      actions.unarchiveDataSetAsync.success({
        projectId,
        datasetId,
      })
    );

    // Reload an entity to update permission flags
    yield put(actions.fetchDataSet(projectId, datasetId));
  } catch (e) {
    yield put(
      actions.unarchiveDataSetAsync.failure(e, {
        projectId,
        datasetId,
      })
    );
  }
}

type GenericItemAction = {
  payload: {
    datasetId: number;
  };
};

export function* datasetsSaga() {
  const selectKey = ({ payload }: GenericItemAction) => payload.datasetId.toString();

  // eslint-disable-next-line redux-saga/no-unhandled-errors
  yield all([
    takeLatest(types.FetchDataSetsActionTypes.REQUEST, loadDataSetList),
    takeLeadingPerKey(types.FetchDataSetActionTypes.REQUEST, loadDataSet, selectKey),
    takeLeadingPerKey(types.ArchiveDataSetActionTypes.REQUEST, archiveDataSet, selectKey),
    takeLeadingPerKey(types.UnarchiveDataSetActionTypes.REQUEST, unarchiveDataSet, selectKey),
  ]);
}
