import React from 'react';
import debounce from 'lodash/debounce';

export interface IWithInfiniteScrollProps {
  loadChunk: () => void;
  shouldLoadChunk?: () => boolean;
  trackScroll: boolean;
}

const shouldLoadChunkDefault = (): boolean =>
  window.innerHeight + window.scrollY > document.body.scrollHeight - 100;

export function withInfiniteScroll<P>(WrappedComponent: React.ComponentType<P>) {
  return class Wrapper extends React.Component<P & IWithInfiniteScrollProps> {
    static displayName = `withInfiniteScroll(${
      WrappedComponent.displayName || WrappedComponent.name || 'Component'
    })`;
    static readonly WrappedComponent = WrappedComponent;
    static defaultProps = {
      shouldLoadChunk: shouldLoadChunkDefault,
      trackScroll: true,
    };

    public componentDidMount() {
      if (this.props.trackScroll) {
        window.addEventListener('scroll', this.handleScroll);
      }
    }

    public componentDidUpdate(prevProps: IWithInfiniteScrollProps) {
      const { trackScroll } = this.props;
      if (prevProps.trackScroll && !trackScroll) {
        window.removeEventListener('scroll', this.handleScroll);
      } else if (!prevProps.trackScroll && trackScroll) {
        window.addEventListener('scroll', this.handleScroll);
      }
    }

    public componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
    }

    private handleScroll = debounce(() => {
      const { loadChunk, shouldLoadChunk } = this.props;
      if (shouldLoadChunk && shouldLoadChunk()) {
        loadChunk();
      }
    }, 200);

    public render() {
      const { loadChunk, shouldLoadChunk, trackScroll, ...props } = this.props;
      return <WrappedComponent {...(props as P)} />;
    }
  };
}
