import { FontWeights, getFocusStyle, getTheme, IStyleFunction } from 'office-ui-fabric-react';
import { IProjectListStyleProps, IProjectListStyles } from './ProjectList.types';

const globalClassNames = {
  item: 'ProjectList-item',
  content: 'ProjectList-content',
  title: 'ProjectList-title',
  timeStamp: 'ProjectList-timeStamp',
  chevron: 'ProjectList-chevron',
};

export const getStyles: IStyleFunction<IProjectListStyleProps, IProjectListStyles> = (props) => {
  const { theme = getTheme() } = props;

  return {
    item: [
      getFocusStyle(theme, { inset: -1 }),
      {
        padding: 10,
        boxSizing: 'border-box',
        borderBottom: `1px solid ${theme.semanticColors.bodyDivider}`,
        display: 'flex',
        selectors: {
          '&:hover': { background: theme.palette.neutralLight },
        },
      },
      globalClassNames.item,
    ],
    itemContent: [
      {
        overflow: 'hidden',
        flexGrow: 1,
      },
      globalClassNames.content,
    ],
    itemTitle: [
      theme.fonts.mediumPlus,
      {
        fontWeight: FontWeights.bold,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      globalClassNames.title,
    ],
    itemTimeStamp: [
      {
        fontSize: theme.fonts.small.fontSize,
        color: theme.palette.neutralTertiary,
        marginTop: 5,
      },
      globalClassNames.timeStamp,
    ],
    chevron: [
      {
        alignSelf: 'center',
        marginLeft: 10,
        color: theme.palette.neutralTertiary,
        fontSize: theme.fonts.large.fontSize,
        flexShrink: 0,
      },
      globalClassNames.chevron,
    ],
  };
};
