const errDescrptionMap: Record<string, string> = {
  google_no_token: 'Токен для Google Analytics не действителен',
  google_no_access:
    'Нет доступа к ресурсу Google Analytics (для устранения проблемы нужно обновить токен для Google Analytics)',
  temporary: 'Временная ошибка',
  unexpected_shutdown: 'Неожиданное завершение задачи',
  yandex_no_token: 'Токен для Яндекс.Директа не действителен',
  yandex_requests_limit: 'Превышен лимит запросов к API Яндекс.Директа',
  yandex_units_limit: 'Недостаточно баллов для выполнения метода API Яндекс.Директа',
};

export const getErrorDescription = (errCode: string) => errDescrptionMap[errCode] || errCode;
