import {
  ApiError,
  callApi as baseCallApi,
  CallApiOptions,
  CallApiResult,
  RequestMethod,
} from '@airmedia/api-utils';
import { fetchAccessToken, isAuthError } from '@airmedia/auth';

type ErrorType = ApiError | Error;

const resolveAccessToken = async () => {
  try {
    return await fetchAccessToken();
  } catch (e) {
    if (isAuthError(e)) {
      return await fetchAccessToken(true);
    } else {
      throw e;
    }
  }
};

export const BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

async function callApi(
  method: RequestMethod,
  endpoint: string,
  options?: CallApiOptions
): Promise<CallApiResult> {
  const url = endpoint.charAt(0) === '/' ? `${BASE_URL}${endpoint}` : endpoint;

  if (options && options.token) {
    return baseCallApi(method, url, options);
  }

  const accessToken = await resolveAccessToken();

  return baseCallApi(method, url, {
    ...(options || {}),
    token: accessToken,
  });
}

export function httpGet(endpoint: string, options?: CallApiOptions): Promise<CallApiResult> {
  return callApi(RequestMethod.GET, endpoint, options);
}

export function httpPost(endpoint: string, options?: CallApiOptions): Promise<CallApiResult> {
  return callApi(RequestMethod.POST, endpoint, options);
}

export function httpPut(endpoint: string, options?: CallApiOptions): Promise<CallApiResult> {
  return callApi(RequestMethod.PUT, endpoint, options);
}

export function httpPatch(endpoint: string, options?: CallApiOptions): Promise<CallApiResult> {
  return callApi(RequestMethod.PATCH, endpoint, options);
}

export function httpDelete(endpoint: string, options?: CallApiOptions): Promise<CallApiResult> {
  return callApi(RequestMethod.DELETE, endpoint, options);
}

export function isNotFound(error: ErrorType): boolean {
  return error instanceof ApiError && error.code === 404;
}

export function isForbidden(error: ErrorType): boolean {
  return error instanceof ApiError && error.code === 403;
}
