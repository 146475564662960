import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import * as selectors from './selectors';
import { ITasksState } from './types';

const taskActions = {
  fetchTask: actions.fetchTask,
  fetchTasks: actions.fetchTasks,
  deleteTask: actions.deleteTask,
};

export { taskActions, selectors as taskSelectors };
export * from './models';
export * from './types';

const initialState: ITasksState = {
  byId: {},
  ids: [],
  isFetching: false,
  total: 0,
  nextPageOffset: 0,
};

type TaskAction = ActionType<typeof actions>;

const byId = createReducer(initialState.byId)
  .handleAction(actions.fetchTasksAsync.request, (state, { payload }) =>
    payload.reset ? initialState.byId : state
  )
  .handleAction(actions.fetchTasksAsync.success, (state, { payload }) => {
    let newState = {
      ...state,
    };
    Object.entries(payload.entities.tasks).forEach(([key, task]) => {
      newState[key] = {
        ...(newState[key] || {}),
        ...task,
      };
    });
    return newState;
  })
  .handleAction(actions.fetchTaskAsync.request, (state, { payload }) => ({
    ...state,
    [payload.taskId]: {
      ...(state[payload.taskId] || {}),
      isFetching: true,
    },
  }))
  .handleAction(actions.fetchTaskAsync.success, (state, { payload }) => ({
    ...state,
    [payload.result]: {
      ...payload.entities.tasks[payload.result],
      isFetching: false,
    },
  }))
  .handleAction(actions.fetchTaskAsync.failure, (state, { meta }) => ({
    ...state,
    [meta.taskId]: {
      ...(state[meta.taskId] || {}),
      isFetching: false,
    },
  }))
  .handleAction(actions.deleteTaskAsync.request, (state, { payload }) => ({
    ...state,
    [payload.taskId]: {
      ...(state[payload.taskId] || {}),
      isDeleting: true,
    },
  }))
  .handleAction(actions.deleteTaskAsync.success, (state, { payload }) => ({
    ...state,
    [payload.result]: {
      ...payload.entities.tasks[payload.result],
      isDeleting: false,
    },
  }))
  .handleAction(actions.deleteTaskAsync.failure, (state, { meta }) => ({
    ...state,
    [meta.taskId]: {
      ...(state[meta.taskId] || {}),
      isDeleting: false,
    },
  }));

const ids = createReducer(initialState.ids)
  .handleAction(actions.fetchTasksAsync.request, (state, { payload }) =>
    payload.reset ? initialState.ids : state
  )
  .handleAction(actions.fetchTasksAsync.success, (state, { payload }) =>
    state.concat(payload.result)
  );

const isFetching = createReducer(initialState.isFetching)
  .handleAction(actions.fetchTasksAsync.request, () => true)
  .handleAction([actions.fetchTasksAsync.success, actions.fetchTasksAsync.failure], () => false);

const total = createReducer(initialState.total)
  .handleAction(actions.fetchTasksAsync.request, (state, { payload }) =>
    payload.reset ? initialState.total : state
  )
  .handleAction(actions.fetchTasksAsync.success, (state, { payload }) => payload.meta.total);

const nextPageOffset = createReducer(initialState.nextPageOffset)
  .handleAction(actions.fetchTasksAsync.request, (state, { payload }) =>
    payload.reset ? initialState.nextPageOffset : state
  )
  .handleAction(actions.fetchTasksAsync.success, (state, { payload: { meta } }) =>
    meta.total > 0 ? Math.min(meta.offset + meta.limit, meta.total) : 0
  );

export default combineReducers<ITasksState, TaskAction>({
  byId,
  ids,
  isFetching,
  total,
  nextPageOffset,
});
