import React from 'react';
import { mergeStyleSets, getTheme } from 'office-ui-fabric-react';
import { computeLoss } from '../../utils/task';

type IStatsValueProps = {
  value: number;
  outValue: number;
  decimals?: number;
};

const theme = getTheme();
const classNames = mergeStyleSets({
  root: {
    textAlign: 'right',
  },
  loss: {
    color: theme.semanticColors.errorText,
  },
});

export const StatsValue: React.FC<IStatsValueProps> = ({ value, outValue, decimals = 0 }) => {
  const [lossAmount] = computeLoss(value, outValue);

  return (
    <div className={classNames.root}>
      <span title="Статистика полученная из Яндекс.Директа">{value.toFixed(decimals)}</span>
      {lossAmount > 0 && (
        <div className={classNames.loss} title="Потери">
          -{lossAmount.toFixed(decimals)}
        </div>
      )}
    </div>
  );
};

export default StatsValue;
