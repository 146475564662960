import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import * as selectors from './selectors';
import { IDataSetsState } from './types';

export { actions as datasetActions, selectors as datasetSelectors };
export * from './models';

const initialState: IDataSetsState = {
  byId: {},
  ids: [],
  isFetching: false,
  total: 0,
};

type DataSetAction = ActionType<typeof actions>;

const byId = createReducer(initialState.byId)
  .handleAction(actions.fetchDataSetsAsync.request, () => initialState.byId)
  .handleAction(actions.fetchDataSetsAsync.success, (state, { payload }) => ({
    ...state,
    ...payload.entities.datasets,
  }))
  .handleAction(actions.fetchDataSetAsync.request, (state, { payload }) => ({
    ...state,
    [payload.datasetId]: {
      ...(state[payload.datasetId] || {}),
      isFetching: true,
    },
  }))
  .handleAction(actions.fetchDataSetAsync.success, (state, { payload }) => ({
    ...state,
    [payload.result.data]: {
      ...payload.entities.datasets[payload.result.data],
      isFetching: false,
    },
  }))
  .handleAction(actions.fetchDataSetAsync.failure, (state, { meta }) => ({
    ...state,
    [meta.datasetId]: {
      ...(state[meta.datasetId] || {}),
      isFetching: false,
    },
  }))
  .handleAction(actions.addDataSet, (state, { payload }) => ({
    ...state,
    [payload.id]: payload,
  }))
  .handleAction(actions.archiveDataSetAsync.request, (state, { payload }) => ({
    ...state,
    [payload.datasetId]: {
      ...(state[payload.datasetId] || {}),
      isStatusChanging: true,
    },
  }))
  .handleAction(actions.archiveDataSetAsync.success, (state, { payload }) => ({
    ...state,
    [payload.datasetId]: {
      ...(state[payload.datasetId] || {}),
      isStatusChanging: false,
      archived: true,
    },
  }))
  .handleAction(actions.archiveDataSetAsync.failure, (state, { meta }) => ({
    ...state,
    [meta.datasetId]: {
      ...(state[meta.datasetId] || {}),
      isStatusChanging: false,
    },
  }))
  .handleAction(actions.unarchiveDataSetAsync.request, (state, { payload }) => ({
    ...state,
    [payload.datasetId]: {
      ...(state[payload.datasetId] || {}),
      isStatusChanging: true,
    },
  }))
  .handleAction(actions.unarchiveDataSetAsync.success, (state, { payload }) => ({
    ...state,
    [payload.datasetId]: {
      ...(state[payload.datasetId] || {}),
      isStatusChanging: false,
      archived: false,
    },
  }))
  .handleAction(actions.unarchiveDataSetAsync.failure, (state, { meta }) => ({
    ...state,
    [meta.datasetId]: {
      ...(state[meta.datasetId] || {}),
      isStatusChanging: false,
    },
  }))
  .handleAction(actions.replaceDataSet, (state, { payload }) => ({
    ...state,
    [payload.id]: payload,
  }));

const ids = createReducer(initialState.ids)
  .handleAction(actions.fetchDataSetsAsync.request, () => initialState.ids)
  .handleAction(actions.fetchDataSetsAsync.success, (state, { payload }) =>
    state.concat(payload.result)
  )
  .handleAction(actions.addDataSet, (state, { payload }) => [payload.id].concat(state));

const isFetching = createReducer(initialState.isFetching)
  .handleAction(actions.fetchDataSetsAsync.request, () => true)
  .handleAction(
    [actions.fetchDataSetsAsync.success, actions.fetchDataSetsAsync.failure],
    () => false
  );

const total = createReducer(initialState.total)
  .handleAction(actions.fetchDataSetsAsync.request, () => initialState.total)
  .handleAction(actions.fetchDataSetsAsync.success, (_, { payload }) => payload.meta.total);

export default combineReducers<IDataSetsState, DataSetAction>({
  byId,
  ids,
  isFetching,
  total,
});
