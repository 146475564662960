import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';
import { fetchProjectDetailsAsync, fetchProjectsAsync } from './actions';
import * as projectActions from './actions';
import * as projectSelectors from './selectors';
import { IProjectsState } from './types';

export { projectActions, projectSelectors };
export * from './models';

const initialState: IProjectsState = {
  byId: {},
  ids: [],
  isFetching: false,
  nextPageOffset: 0,
  total: 0,
};

type ProjectsAction = ActionType<typeof projectActions>;

const byId = createReducer(initialState.byId)
  .handleAction(fetchProjectsAsync.request, (state, { payload: shouldReset }) =>
    shouldReset ? initialState.byId : state
  )
  .handleAction(fetchProjectsAsync.success, (state, { payload }) => ({
    ...state,
    ...payload.entities.projects,
  }))
  .handleAction(fetchProjectDetailsAsync.request, (state, { payload: projectId }) => ({
    ...state,
    [projectId]: {
      ...(state[projectId] || {}),
      isFetching: true,
    },
  }))
  .handleAction(fetchProjectDetailsAsync.success, (state, { payload }) => ({
    ...state,
    ...payload.entities.projects,
  }))
  .handleAction(fetchProjectDetailsAsync.failure, (state, { meta }) => ({
    ...state,
    [meta.projectId]: {
      ...(state[meta.projectId] || {}),
      isFetching: false,
    },
  }));

const ids = createReducer(initialState.ids)
  .handleAction(fetchProjectsAsync.request, (state, { payload: shouldReset }) =>
    shouldReset ? initialState.ids : state
  )
  .handleAction(fetchProjectsAsync.success, (state, { payload }) => state.concat(payload.result));

const isFetching = createReducer(initialState.isFetching)
  .handleAction(fetchProjectsAsync.request, () => true)
  .handleAction([fetchProjectsAsync.success, fetchProjectsAsync.failure], () => false);

const nextPageOffset = createReducer(
  initialState.nextPageOffset
).handleAction(fetchProjectsAsync.success, (state, { payload: { meta } }) =>
  meta.total > 0 ? Math.min(meta.offset + meta.limit, meta.total) : 0
);

const total = createReducer(initialState.total).handleAction(
  fetchProjectsAsync.success,
  (state, { payload: { meta } }) => meta.total
);

export default combineReducers<IProjectsState, ProjectsAction>({
  byId,
  ids,
  isFetching,
  nextPageOffset,
  total,
});
