import React from 'react';
import { styled } from 'office-ui-fabric-react';
import PageBase from './Page';
import { getStyles } from './styles';
import { IPageProps, IPageStyleProps, IPageStyles } from './types';

export * from './types';

export const Page: React.FC<IPageProps> = styled<IPageProps, IPageStyleProps, IPageStyles>(
  PageBase,
  getStyles,
  undefined,
  {
    scope: 'Page',
  }
);

export default Page;
