import { buildUrl } from '@airmedia/api-utils';
import { camelize, camelizeKeys } from 'humps';
import { schema } from 'normalizr';
import omit from 'lodash/omit';
import { httpGet } from '../../utils/restApi';
import { IProjectDetailsResponse, IProjectListResponse } from './types';

export * from './types';

const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://projects.airmediapro.ru' : '';

type RawEntity = Record<string, any>;

function mergeToParent(entity: RawEntity, keyOfValueObject: string): RawEntity {
  if (entity[keyOfValueObject]) {
    return omit(
      Object.entries(entity[keyOfValueObject]).reduce((acc, [key, value]) => {
        acc[camelize(`${keyOfValueObject} ${key}`)] = value;
        return acc;
      }, entity),
      keyOfValueObject
    );
  }
  return entity;
}

const projectSchema = new schema.Entity(
  'projects',
  {},
  {
    processStrategy: (entity) => {
      let result: RawEntity = camelizeKeys(entity);
      result = mergeToParent(result, 'googleAnalytics');
      result = mergeToParent(result, 'user');
      result = mergeToParent(result, 'yandexDirect');

      return result;
    },
  }
);

export async function fetchProjects(
  limit: number,
  offset: number = 0
): Promise<IProjectListResponse> {
  const endpoint = buildUrl(`${BASE_URL}/api/projects`, {
    limit: limit.toString(),
    offset: offset.toString(),
  });
  const result = await httpGet(endpoint, {
    schema: [projectSchema],
  });

  if (!result.payload) {
    throw new Error('Unknown result from API method');
  }

  return result.payload as IProjectListResponse;
}

export async function fetchProjectById(id: string): Promise<IProjectDetailsResponse> {
  const result = await httpGet(`${BASE_URL}/api/projects/${id}`, {
    schema: projectSchema,
  });

  if (!result.payload) {
    throw new Error('Unknown result from API method');
  }

  return result.payload as IProjectDetailsResponse;
}
