import { camelize } from 'humps';
import { FormikValues, FormikErrors, getIn, setIn } from 'formik';
import { ApiErrorItem } from '@airmedia/api-utils';

export interface TransformOptions {
  canMapped?: (formPath: string, formValue: any) => boolean;
  normalizeFormPath?: (formPath: string) => string;
  translate?: (formPath: string, formValue: any, err: ApiErrorItem) => string;
  onIgnore?: (err: ApiErrorItem, formPath: string) => void;
}

const propertyToFormPath = (property: string): string =>
  camelize(property.replace(/^data(\.|$)/, ''));

const defaultTransformOptions: TransformOptions = {
  canMapped: (formPath, formValue) => !!(formPath && formValue !== undefined),
  normalizeFormPath: (formPath) => formPath,
  translate: (formPath, formValue, err) => err.message,
};

export const transformApiErrorsToFormErrors = <T extends FormikValues>(
  values: T,
  apiErrors: ApiErrorItem[],
  options?: TransformOptions
): FormikErrors<T> => {
  let formErrors = {};
  const opt: TransformOptions = {
    ...defaultTransformOptions,
    ...(options || {}),
  };
  apiErrors.forEach((err) => {
    const formPath = opt.normalizeFormPath!(propertyToFormPath(err.property));
    const formValue = getIn(values, formPath);
    if (opt.canMapped!(formPath, formValue)) {
      if (!getIn(formErrors, formPath)) {
        formErrors = setIn(formErrors, formPath, opt.translate!(formPath, formValue, err));
      }
    } else if (opt.onIgnore) {
      opt.onIgnore(err, formPath);
    }
  });
  return formErrors;
};
