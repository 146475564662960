import React from 'react';
import { styled } from '@uifabric/utilities';
import ProjectListBase from './ProjectList';
import { IProjectListProps, IProjectListStyleProps, IProjectListStyles } from './ProjectList.types';
import { getStyles } from './ProjectList.styles';

export * from './ProjectList.types';

export const ProjectList: React.FC<IProjectListProps> = styled<
  IProjectListProps,
  IProjectListStyleProps,
  IProjectListStyles
>(ProjectListBase, getStyles, undefined, {
  scope: 'ProjectList',
});

export default ProjectList;
