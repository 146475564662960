import { FormikBag, withFormik } from 'formik';
import { ApiError } from '@airmedia/api-utils';
import * as api from '../../api';
import NewPeriodForm from './Form';
import { IFormProps, IFormValues } from './types';
import { formatLocaleDate, formatYmd } from '../../utils/date';
import { handleError } from '../../utils/formik';

export async function handleSubmit(
  values: IFormValues,
  formBag: FormikBag<IFormProps, IFormValues>
) {
  const {
    setStatus,
    resetForm,
    props: { projectId, datasetId, onAdded },
  } = formBag;

  try {
    await api.addPeriod(projectId, datasetId, {
      periodStart: formatYmd(values.startPeriod!),
      periodEnd: formatYmd(values.endPeriod),
    });

    setStatus(null);
    resetForm();
    onAdded();
  } catch (e) {
    handleError(e, values, formBag);

    if (e instanceof ApiError && e.code === 409) {
      setStatus('Выбранный период конфликтует с одной или несколькими задачами.');
    }
  }
}

export function validate(values: IFormValues) {
  if (!values.startPeriod) {
    return {
      startPeriod: 'Необходимо выбрать начало периода',
    };
  } else if (values.startPeriod > values.endPeriod) {
    return {
      startPeriod: `Начало периода не может быть больше чем ${formatLocaleDate(values.endPeriod)}`,
    };
  }
}

export default withFormik<IFormProps, IFormValues>({
  handleSubmit,
  validate,
  validateOnBlur: false,
  mapPropsToValues: () => ({
    endPeriod: new Date(Date.now() - 1000 * 60 * 60 * 24),
  }),
})(NewPeriodForm);
