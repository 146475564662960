import { FormikBag, FormikErrors, withFormik } from 'formik';
import { signIn } from '@airmedia/auth';
import SignInForm from './Form';
import { IFormProps, IFormValues } from './types';
import { isEmail } from '../../utils/validators';
import { handleError } from '../../utils/formik';

export async function handleSubmit(
  values: IFormValues,
  formBag: FormikBag<IFormProps, IFormValues>
) {
  const {
    setSubmitting,
    setStatus,
    props: { onAuthorized },
  } = formBag;

  try {
    await signIn(values.email, values.password);

    setStatus(null);
    setSubmitting(false);
    onAuthorized();
  } catch (e) {
    handleError(e, values, formBag);
    setSubmitting(false);
  }
}

export const validate = (values: IFormValues): FormikErrors<IFormValues> => {
  const errors: FormikErrors<IFormValues> = {};

  if (!isEmail(values.email)) {
    errors.email = 'Укажите адрес электронной почты';
  }

  return errors;
};

export default withFormik<IFormProps, IFormValues>({
  handleSubmit,
  validate,
  mapPropsToValues: (): IFormValues => ({
    email: '',
    password: '',
  }),
})(SignInForm);
