import * as yup from 'yup';

export const createStandardSchema = (): yup.ObjectSchema =>
  yup.object({
    name: yup.string().required(),
    dataSource: yup.string().required('Необходимо выбрать набор данных'),
    reportType: yup.mixed().oneOf(['by_term', 'by_content']),
  });

export const createStaticSchema = (): yup.ObjectSchema =>
  yup
    .object({
      utmSource: yup.string().required(),
      utmMedium: yup.string().required(),
      utmCampaign: yup.string().required(),
      utmTerm: yup.string().required(),
      utmContent: yup.string().when('reportType', {
        is: (reportType) => reportType === 'by_content',
        then: yup.string().required(),
        otherwise: yup.string().notRequired(),
      }),
    })
    .concat(createStandardSchema());
