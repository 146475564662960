import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  IBreadcrumbItem,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Link,
  Stack,
} from 'office-ui-fabric-react';
import Breadcrumb from '../connected/Breadcrumb';
import ProfileMenu from '../connected/ProfileMenu';
import { buildBreadcrumbItems } from '../utils/breadcrumb';
import logo from '../logo.svg';
import { HOME_PATH } from '../routes';

const stackStyles: IStackStyles = {
  root: {
    padding: '10px 25px',
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 'l2',
  padding: 'l2',
};

const breadcrumbWrapperStyles: IStackItemStyles = {
  root: {
    selectors: {
      '.ms-Breadcrumb': {
        marginTop: '0px',
      },
    },
  },
};

export const Header = () => {
  const location = useLocation();
  const history = useHistory();
  const handleBreadcrumbClick = React.useCallback(
    (ev?: React.MouseEvent<HTMLElement>, item?: IBreadcrumbItem) => {
      if (item === undefined || item.href === undefined) {
        return;
      }

      if (ev !== undefined) {
        ev.preventDefault();
      }

      history.push(item.href);
    },
    [history]
  );
  const breadcrumbItems = React.useMemo(
    () =>
      buildBreadcrumbItems(location.pathname).map((item) => ({
        ...item,
        onClick: handleBreadcrumbClick,
      })),
    [location, handleBreadcrumbClick]
  );
  const onSignout = React.useCallback(() => {
    history.push(HOME_PATH);
  }, [history]);

  return (
    <Stack
      as="header"
      styles={stackStyles}
      tokens={stackTokens}
      horizontal
      horizontalAlign="space-between"
      verticalAlign="center"
    >
      <Stack.Item>
        <Link href="/">
          <img src={logo} alt="Logo" width={48} height={48} />
        </Link>
      </Stack.Item>
      <Stack.Item grow disableShrink styles={breadcrumbWrapperStyles}>
        <Breadcrumb items={breadcrumbItems} />
      </Stack.Item>
      <Stack.Item>
        <ProfileMenu onSignout={onSignout} />
      </Stack.Item>
    </Stack>
  );
};

export default Header;
