import React from 'react';
import { Field, Form, FormikProps } from 'formik';
import {
  DayOfWeek,
  DefaultButton,
  Dialog,
  DialogFooter,
  IStackTokens,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
} from 'office-ui-fabric-react';
import { FormikDatePicker } from 'formik-office-ui-fabric-react';
import { IFormProps, IFormValues } from './types';
import { formatLocaleDate } from '../../utils/date';
import { datePickerStrings } from '../../utils/datePicker';

const stackTokens: IStackTokens = {
  childrenGap: 15,
};

export const NewPeriodForm = ({
  isSubmitting,
  status,
  values,
  onCloseDialog,
  errors,
}: IFormProps & FormikProps<IFormValues>) => (
  <Dialog
    hidden={false}
    dialogContentProps={{
      title: 'Новый период',
      subText: 'Добавить задачи для загрузки данных за выбранный период.',
      showCloseButton: true,
    }}
    modalProps={{
      isBlocking: true,
    }}
    minWidth={480}
    onDismiss={isSubmitting ? undefined : onCloseDialog}
  >
    <Form>
      <Stack tokens={stackTokens}>
        <Stack.Item>
          <Field
            name="startPeriod"
            label="Начало периода"
            isRequired={true}
            disabled={isSubmitting}
            component={FormikDatePicker}
            formatDate={formatLocaleDate}
            firstDayOfWeek={DayOfWeek.Monday}
            strings={datePickerStrings}
            minDate={new Date(Date.now() - 1000 * 60 * 60 * 24 * 1035)}
            maxDate={values.endPeriod}
          />
        </Stack.Item>
        <Stack.Item>
          <Field
            name="endPeriod"
            label="Конец периода"
            isRequired={true}
            disabled={isSubmitting}
            component={FormikDatePicker}
            formatDate={formatLocaleDate}
            firstDayOfWeek={DayOfWeek.Monday}
            strings={datePickerStrings}
            minDate={values.startPeriod}
            maxDate={new Date(Date.now() - 1000 * 60 * 60 * 24)}
          />
        </Stack.Item>
        {(status || errors.startPeriod) && (
          <MessageBar messageBarType={MessageBarType.error}>
            {status || errors.startPeriod}
          </MessageBar>
        )}
      </Stack>
      <DialogFooter>
        <PrimaryButton type="submit" text="Сохранить" disabled={isSubmitting} />
        <DefaultButton
          type="button"
          text="Отмена"
          onClick={onCloseDialog}
          disabled={isSubmitting}
        />
      </DialogFooter>
    </Form>
  </Dialog>
);

export default NewPeriodForm;
