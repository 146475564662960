const EMAIL_RE = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
const DOMAIN_PATTERN_RE = /^(?:(?:[a-z\d-]+|\*)\.)*?[a-z\d-]+\.[a-z\d-]+$/i;

export const isEmail = (value?: string): boolean => {
  if (!value) {
    return false;
  }
  return EMAIL_RE.test(value);
};

export const isDomainPattern = (value: string): boolean => DOMAIN_PATTERN_RE.test(value);
