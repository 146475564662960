import {
  getGlobalClassNames,
  getTheme,
  IButtonStyles,
  ICommandBarItemProps,
} from 'office-ui-fabric-react';
import { IPageStyleProps, IPageStyles } from './types';

const globalClassNames = {
  root: 'Page',
  main: 'Page-main',
};

export function getStyles(props: IPageStyleProps): IPageStyles {
  const { className, theme } = props;
  const commandBarBackgroundColor = theme.semanticColors.bodyStandoutBackground;
  const classNames = getGlobalClassNames(globalClassNames, theme);

  return {
    root: [classNames.root, className],
    main: [
      classNames.main,
      {
        padding: '20px 32px',
      },
    ],
    subComponentStyles: {
      commandBar: {
        root: {
          backgroundColor: commandBarBackgroundColor,
        },
        primarySet: {
          backgroundColor: commandBarBackgroundColor,
        },
        secondarySet: {
          backgroundColor: commandBarBackgroundColor,
        },
      },
    },
  };
}

const commandBarBackgroundColor = getTheme().semanticColors.bodyStandoutBackground;

const commandBarButtonStyles: IButtonStyles = {
  root: {
    backgroundColor: commandBarBackgroundColor,
  },
  rootDisabled: {
    backgroundColor: commandBarBackgroundColor,
  },
};

export function injectButtonStyles(items?: ICommandBarItemProps[]) {
  if (!items) {
    return items;
  }
  return items.map((item) => ({
    ...item,
    buttonStyles: commandBarButtonStyles,
  }));
}
