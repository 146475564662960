export type IProfile = Readonly<{
  id: string;
  email: string;
}>;

export type ISessionState = Readonly<{
  profile: IProfile | null;
  authenticated: boolean;
}>;

export enum FetchProfileActionTypes {
  REQUEST = 'session.profile.FETCH_REQUEST',
  SUCCESS = 'session.profile.FETCH_SUCCESS',
  FAILURE = 'session.profile.FETCH_FAILURE',
}
