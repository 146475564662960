import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  classNamesFunction,
  getRTL,
  Icon,
  IProcessedStyleSet,
  Link,
  List,
} from 'office-ui-fabric-react';
import { RelativeTime } from '@airmedia/react-time';
import { IProject } from '../../store';
import { IProjectListProps, IProjectListStyleProps, IProjectListStyles } from './ProjectList.types';
import { generatePathForRoute } from '../../utils/route';

const getClassNames = classNamesFunction<IProjectListStyleProps, IProjectListStyles>();

export class ProjectList extends React.Component<IProjectListProps> {
  private _classNames: IProcessedStyleSet<IProjectListStyles> | undefined;

  public render(): JSX.Element {
    const { projects, styles, theme } = this.props;
    this._classNames = getClassNames(styles, { theme });

    return <List items={projects} onRenderCell={this.onRenderItem} data-is-scrollable={true} />;
  }

  private onRenderItem = (item?: IProject): JSX.Element | null => {
    if (!item) {
      return null;
    }

    const classNames = this._classNames as IProcessedStyleSet<IProjectListStyles>;
    const href = generatePathForRoute('project', { project: item.id });

    return (
      <div className={classNames.item} data-is-focusable={true}>
        <div className={classNames.itemContent}>
          <Link as={RouterLink as any} to={href} className={classNames.itemTitle}>
            {item.name}
          </Link>
          <div className={classNames.itemTimeStamp}>
            Создан <RelativeTime date={item.createdAt} />
          </div>
        </div>
        <Link as={RouterLink as any} to={href} className={classNames.chevron}>
          <Icon iconName={getRTL() ? 'ChevronLeft' : 'ChevronRight'} />
        </Link>
      </div>
    );
  };
}

export default ProjectList;
