import React from 'react';
import {
  classNamesFunction,
  CommandBar,
  MessageBar,
  IMessageBarProps,
} from 'office-ui-fabric-react';
import { injectButtonStyles } from './styles';
import { IPageProps, IPageStyleProps, IPageStyles, IPageSubComponentStyles } from './types';

const getClassNames = classNamesFunction<IPageStyleProps, IPageStyles>();

const defaultMessageBarProps: IMessageBarProps = {};

export const Page: React.FC<IPageProps> = ({
  commandBarItems,
  commandBarFarItems,
  children,
  messageBarContent,
  messageBarProps,
  styles,
  theme,
}) => {
  const items = React.useMemo(() => injectButtonStyles(commandBarItems), [commandBarItems]);
  const farItems = React.useMemo(() => injectButtonStyles(commandBarFarItems), [
    commandBarFarItems,
  ]);

  const classNames = getClassNames(styles, {
    theme: theme!,
  });
  const commandBarStyles = classNames.subComponentStyles
    ? (classNames.subComponentStyles as IPageSubComponentStyles).commandBar
    : undefined;

  return (
    <div className={classNames.root}>
      {messageBarContent && (
        <MessageBar {...(messageBarProps || defaultMessageBarProps)}>
          {messageBarContent}
        </MessageBar>
      )}
      <CommandBar items={items || []} farItems={farItems} styles={commandBarStyles} />
      <main className={classNames.main}>{children}</main>
    </div>
  );
};

export default Page;
