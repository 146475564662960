import React from 'react';
import {
  DefaultButton,
  getTheme,
  mergeStyles,
  Panel,
  PanelType,
  Separator,
} from 'office-ui-fabric-react';
import { RelativeTime } from '@airmedia/react-time';
import { IDataSet, ITask } from '../../store';
import { canBeRestarted, canDelete, isResolvingUploadStatus } from '../../utils/task';
import DescriptionList from '../DescriptionList';
import TaskStatus from '../TaskStatus';
import RestartStatus from '../RestartStatus';
import UploadStatus from '../UploadStatus';
import PrepareState from './PrepareState';
import StatsDetails from './StatsDetails';
import ReportOptionsDescriptionList from '../ReportOptionsDescriptionList';
import { getErrorDescription } from './utils';

export interface ITaskPanelProps {
  task: ITask;
  dataset: IDataSet;
  allowDeleteTask: boolean;
  onClosePanel: () => void;
  onDeleteTask: (task: ITask) => void;
}

const theme = getTheme();
const errorClassName = mergeStyles({
  color: theme.semanticColors.errorText,
});

export const TaskPanel: React.FC<ITaskPanelProps> = ({
  dataset,
  task,
  allowDeleteTask,
  onClosePanel,
  onDeleteTask,
}) => {
  const deleteTask = React.useCallback(() => {
    onDeleteTask(task);
  }, [onDeleteTask, task]);
  const onRenderFooterContent = React.useCallback(() => {
    if (!canDelete(task, dataset)) {
      return null;
    }

    return (
      <DefaultButton
        iconProps={{
          iconName: 'Delete',
        }}
        disabled={allowDeleteTask && task.isDeleting}
        text="Удалить"
        onClick={deleteTask}
      />
    );
  }, [dataset, task, deleteTask, allowDeleteTask]);

  return (
    <Panel
      isOpen={true}
      isLightDismiss={true}
      type={PanelType.largeFixed}
      hasCloseButton={true}
      onDismiss={onClosePanel}
      headerText={task.id ? `Задача № ${task.id}` : ''}
      isFooterAtBottom={true}
      onRenderFooterContent={onRenderFooterContent}
    >
      {!task.reportOptions && <PrepareState isFetching={task.isFetching} />}
      {task.reportOptions && (
        <>
          <DescriptionList>
            <dt>Статус</dt>
            <dd>
              <TaskStatus stage={task.stage} status={task.status} />
            </dd>
            <dd>
              {isResolvingUploadStatus(task.stage, task.status) ? (
                <>
                  В последний раз статус выгрузки проверялся{' '}
                  <RelativeTime date={task.statusUpdatedAt} />
                </>
              ) : (
                <RelativeTime date={task.statusUpdatedAt} />
              )}
            </dd>
            {task.errCode && (
              <>
                <dt>Причина ошибки</dt>
                <dd>{getErrorDescription(task.errCode)}</dd>
              </>
            )}
            {canBeRestarted(task) && (
              <>
                <dt>Повторный запуск</dt>
                <dd>
                  <RestartStatus restart={task.restart} />
                </dd>
              </>
            )}
          </DescriptionList>
          <Separator>Настройки</Separator>
          <ReportOptionsDescriptionList options={task.reportOptions} />
          <Separator>Загрузка</Separator>
          {task.upload && (
            <DescriptionList>
              <dt>Статус</dt>
              <dd>
                <UploadStatus status={task.upload.status} />
              </dd>
              <dd>
                <RelativeTime date={task.upload.statusUpdatedAt} />
              </dd>
              {task.upload.errors && task.upload.errors.length > 0 && (
                <>
                  <dt>Ошибки</dt>
                  {task.upload.errors.map((error) => (
                    <dd key={error} className={errorClassName}>
                      {error}
                    </dd>
                  ))}
                </>
              )}
            </DescriptionList>
          )}
          {task.fetchStats && task.fileStats && (
            <>
              <Separator>Статистика</Separator>
              <StatsDetails fetchStats={task.fetchStats} fileStats={task.fileStats} />
            </>
          )}
        </>
      )}
    </Panel>
  );
};

export default TaskPanel;
