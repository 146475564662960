import { DataSetType } from '../store';

export const translate = (value: DataSetType): string => {
  switch (value) {
    case DataSetType.Standard:
      return 'Базовый';
    case DataSetType.Static:
      return 'Статический';
    default:
      return 'Неопределено';
  }
};
