import React from 'react';
import { connect } from 'react-redux';
import Types from 'AppTypes';
import { Selection, SelectionMode } from 'office-ui-fabric-react';
import TaskListBase, { ITaskListProps } from '../components/TaskList';
import { withInfiniteScroll } from '../hoc/withInfiniteScroll';
import { ITask, taskActions, taskSelectors } from '../store';

type IStateProps = {
  tasks: ITask[];
  total: number;
  isFetching: boolean;
};

type IDispatchProps = {
  loadTasks: typeof taskActions.fetchTasks;
};

const mapStateToProps = (state: Types.RootState): IStateProps => ({
  tasks: taskSelectors.getTasks(state),
  total: taskSelectors.getTotal(state),
  isFetching: taskSelectors.isFetching(state),
});

const mapDispatchToProps: IDispatchProps = {
  loadTasks: taskActions.fetchTasks,
};

const TaskListInfiniteScroll = withInfiniteScroll<ITaskListProps>(TaskListBase);

type IOwnProps = {
  projectId: string;
  datasetId: number;
  onSelectTask: (task: ITask) => void;
};

type ITaskListConnectedProps = IStateProps & IDispatchProps & IOwnProps;

export class TaskList extends React.Component<ITaskListConnectedProps> {
  private selection: Selection;

  public constructor(props: ITaskListConnectedProps) {
    super(props);

    this.selection = new Selection({
      selectionMode: SelectionMode.single,
      onSelectionChanged: this.handleSelectionChanged,
    });
  }

  public componentDidMount(): void {
    this.props.loadTasks(this.props.projectId, this.props.datasetId, true);
  }

  private onLoadChunk = () => {
    this.props.loadTasks(this.props.projectId, this.props.datasetId);
  };

  private handleSelectionChanged = () => {
    if (this.selection.getSelectedCount() > 0) {
      this.props.onSelectTask(this.selection.getSelection()[0] as ITask);
    }
  };

  public render() {
    const { isFetching, tasks, total } = this.props;
    const shouldTrackScroll = !isFetching && tasks.length < total;

    return (
      <TaskListInfiniteScroll
        trackScroll={shouldTrackScroll}
        loadChunk={this.onLoadChunk}
        tasks={tasks}
        isFetching={isFetching}
        selection={this.selection}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);
