import { TaskStageEnum, TaskStatusEnum } from '../../store';

const stageIndexes = {
  [TaskStageEnum.BUILD]: 1,
  [TaskStageEnum.UPLOAD]: 2,
  [TaskStageEnum.CHECK_STATUS]: 3,
  [TaskStageEnum.DELETE]: 4,
};

export function isDependOn(targetStage: TaskStageEnum, onStage: TaskStageEnum) {
  return stageIndexes[targetStage] > stageIndexes[onStage];
}

export function resolveStatusForStage(
  targetStage: TaskStageEnum,
  status: TaskStatusEnum,
  currentStage: TaskStageEnum
): TaskStatusEnum {
  switch (currentStage) {
    case TaskStageEnum.DELETE:
      return targetStage === TaskStageEnum.DELETE ? status : TaskStatusEnum.COMPLETED;
    case TaskStageEnum.CHECK_STATUS:
      return targetStage === TaskStageEnum.CHECK_STATUS ? status : TaskStatusEnum.COMPLETED;
    case TaskStageEnum.UPLOAD:
      if (targetStage === TaskStageEnum.UPLOAD) {
        return status;
      }
      if (targetStage === TaskStageEnum.CHECK_STATUS) {
        return status === TaskStatusEnum.SKIPPED ? status : TaskStatusEnum.PENDING;
      }
      return TaskStatusEnum.COMPLETED;
    case TaskStageEnum.BUILD:
      if (targetStage === TaskStageEnum.BUILD) {
        return status;
      }
      if (targetStage === TaskStageEnum.UPLOAD || targetStage === TaskStageEnum.CHECK_STATUS) {
        return status === TaskStatusEnum.SKIPPED ? status : TaskStatusEnum.PENDING;
      }
  }

  return status;
}
