import React from 'react';
import { FontSizes, getTheme, mergeStyles } from 'office-ui-fabric-react';

interface IDescriptionListProps {
  children: React.ReactNode;
}

const theme = getTheme();
const className = mergeStyles({
  selectors: {
    '& dt': {
      fontSize: FontSizes.small,
      marginBottom: 3,
      color: theme.palette.neutralSecondary,
    },
    '& dd': {
      margin: 0,
    },
    '& dd ~ dt': {
      marginTop: 15,
    },
  },
});

export const DescriptionList: React.FC<IDescriptionListProps> = ({ children }) => (
  <dl className={className}>{children}</dl>
);

export default DescriptionList;
