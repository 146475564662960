import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { takeLeadingPerKey } from '../../utils/saga';
import * as projectApi from '../../api/projects';
import { fetchProjectDetailsAsync, fetchProjectsAsync } from './actions';
import { getNextPageOffset } from './selectors';
import { FetchProjectDetailsActionTypes, FetchProjectsActionTypes } from './types';

type LoadProjectListAction = ReturnType<typeof fetchProjectsAsync.request>;
type LoadProjectAction = ReturnType<typeof fetchProjectDetailsAsync.request>;

export function* loadProjects(action: LoadProjectListAction): SagaIterator {
  try {
    const offset = yield select(getNextPageOffset);
    const response: projectApi.IProjectListResponse = yield call(
      projectApi.fetchProjects,
      20,
      action.payload ? 0 : offset
    );

    yield put(fetchProjectsAsync.success(response));
  } catch (e) {
    yield put(fetchProjectsAsync.failure(e));
  }
}

export function* loadProjectDetails(action: LoadProjectAction): SagaIterator {
  try {
    const response: projectApi.IProjectDetailsResponse = yield call(
      projectApi.fetchProjectById,
      action.payload
    );

    yield put(fetchProjectDetailsAsync.success(response));
  } catch (e) {
    yield put(
      fetchProjectDetailsAsync.failure(e, {
        projectId: action.payload,
      })
    );
  }
}

export function* projectsSaga() {
  // eslint-disable-next-line redux-saga/no-unhandled-errors
  yield all([
    takeLatest(FetchProjectsActionTypes.REQUEST, loadProjects),
    takeLeadingPerKey(
      FetchProjectDetailsActionTypes.REQUEST,
      loadProjectDetails,
      ({ payload }) => payload
    ),
  ]);
}
