import React from 'react';
import { connect } from 'react-redux';
import Types from 'AppTypes';
import { IMessageBarProps, MessageBarType, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { ICommandBarItemProps, IPageProps } from '../components/Page';
import Page from './Page';
import { IProject, projectActions, projectSelectors } from '../store';
import { googleIntegrationEnabled, yandexIntegrationEnabled } from '../utils/project';

type IStateProps = {
  project?: IProject;
};

type IDispatchProps = {
  loadProject: typeof projectActions.fetchProjectDetails;
};

type IOwnProps = {
  projectId: string;
};

export type IProjectAwarePageProps = IStateProps & IDispatchProps & IOwnProps & IPageProps;

const mapStateToProps = (state: Types.RootState, { projectId }: IOwnProps): IStateProps => ({
  project: projectSelectors.getProject(state, projectId),
});

const mapDispatchToProps: IDispatchProps = {
  loadProject: projectActions.fetchProjectDetails,
};

const messageBarProps: IMessageBarProps = {
  messageBarType: MessageBarType.warning,
};

const overrideCommands = (
  commands: ICommandBarItemProps[] | undefined,
  integrationsDisabled: boolean
): ICommandBarItemProps[] | undefined => {
  if (commands === undefined) {
    return commands;
  }

  return commands.map((item) => {
    if (integrationsDisabled && item.requireEnabledIntegrations) {
      return {
        ...item,
        disabled: true,
      };
    }
    if (item.subMenuProps !== undefined) {
      item.subMenuProps.items = overrideCommands(
        item.subMenuProps.items,
        integrationsDisabled
      ) as ICommandBarItemProps[];
    }
    return item;
  });
};

export class ProjectAwarePage extends React.Component<IProjectAwarePageProps> {
  public componentDidMount(): void {
    this.loadProject();
  }

  public componentDidUpdate(prevProps: Readonly<IProjectAwarePageProps>): void {
    if (this.props.projectId !== prevProps.projectId) {
      this.loadProject();
    }
  }

  public render() {
    const { project, commandBarItems, commandBarFarItems, ...rest } = this.props;

    if (
      project !== undefined &&
      (project.yandexDirectStatus === undefined || project.googleAnalyticsStatus === undefined) &&
      project.isFetching
    ) {
      return (
        <Spinner label="Пожалуйста, подождите..." labelPosition="right" size={SpinnerSize.large} />
      );
    }

    const integrationsDisabled =
      !googleIntegrationEnabled(project) || !yandexIntegrationEnabled(project);

    return (
      <Page
        messageBarContent={this.renderMessage()}
        messageBarProps={messageBarProps}
        commandBarItems={overrideCommands(commandBarItems, integrationsDisabled)}
        commandBarFarItems={overrideCommands(commandBarFarItems, integrationsDisabled)}
        {...(rest as IPageProps)}
      />
    );
  }

  private loadProject() {
    const { project, loadProject, projectId } = this.props;
    if (
      project === undefined ||
      project.googleAnalyticsStatus === undefined ||
      project.yandexDirectStatus === undefined
    ) {
      loadProject(projectId);
    }
  }

  private renderMessage() {
    const { project } = this.props;

    if (!yandexIntegrationEnabled(project)) {
      return 'Некоторые функции заблокированы. Необходимо подключить Яндекс.Директ в настройках проекта.';
    }

    if (!googleIntegrationEnabled(project)) {
      return 'Некоторые функции заблокированы. Необходимо подключить Google Analytics в настройках проекта.';
    }

    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAwarePage);
