import React from 'react';
import { getTheme, Icon, IIconProps, mergeStyleSets, TooltipHost } from 'office-ui-fabric-react';
import { TaskStageEnum, TaskStatusEnum } from '../../store';
import { isDependOn, resolveStatusForStage } from './utils';
import { isResolvingUploadStatus } from '../../utils/task';

type ITaskStageProps = {
  stage: TaskStageEnum;
  status: TaskStatusEnum;
  allowRestart?: boolean;
};

const palette = getTheme().palette;
const iconSize = '16px';

function getIconForStatus(
  status: TaskStatusEnum,
  disabled: boolean,
  allowRestart?: boolean
): IIconProps {
  switch (status) {
    case TaskStatusEnum.PENDING:
      return {
        iconName: 'Timer',
        styles: {
          root: {
            fontSize: iconSize,
            color: disabled ? palette.neutralLight : palette.yellowDark,
          },
        },
      };
    case TaskStatusEnum.RUNNING:
      return {
        iconName: 'ProgressRingDots',
        styles: {
          root: {
            fontSize: iconSize,
            color: disabled ? palette.neutralLight : palette.blue,
          },
        },
      };
    case TaskStatusEnum.COMPLETED:
      return {
        iconName: 'Completed',
        styles: {
          root: {
            fontSize: iconSize,
            color: disabled ? palette.neutralLight : palette.green,
          },
        },
      };
    case TaskStatusEnum.FAILED:
      if (allowRestart) {
        return {
          iconName: 'Error',
          styles: {
            root: {
              fontSize: iconSize,
              color: disabled ? palette.neutralLight : palette.orangeLighter,
            },
          },
        };
      }

      return {
        iconName: 'ErrorBadge',
        styles: {
          root: {
            fontSize: iconSize,
            color: disabled ? palette.neutralLight : palette.orange,
          },
        },
      };
    case TaskStatusEnum.SKIPPED:
      return {
        iconName: 'Blocked',
        styles: {
          root: {
            fontSize: iconSize,
            color: palette.neutralQuaternary,
          },
        },
      };
  }

  return {
    iconName: 'Unknown',
    styles: {
      root: {
        fontSize: iconSize,
      },
    },
  };
}

function getIconForCheckStatus(
  stage: TaskStageEnum,
  status: TaskStatusEnum,
  allowRestart?: boolean
): IIconProps {
  if (isResolvingUploadStatus(stage, status)) {
    return {
      iconName: 'WorkFlow',
      styles: {
        root: {
          fontSize: iconSize,
          color: palette.blue,
        },
      },
    };
  }

  return getIconForStatus(
    resolveStatusForStage(TaskStageEnum.CHECK_STATUS, status, stage),
    isDependOn(TaskStageEnum.CHECK_STATUS, stage),
    allowRestart
  );
}

let classNames = {
  root: 'TaskStage-root',
  item: 'TaskStage-item',
};

classNames = mergeStyleSets({
  root: [
    classNames.root,
    {
      selectors: {
        [`& .ms-TooltipHost:first-child .${classNames.item}`]: {
          marginLeft: 0,
        },
        [`& .ms-TooltipHost:not(:last-child) .${classNames.item}:after`]: {
          content: '""',
          position: 'absolute',
          top: 8,
          right: -5,
          width: 5,
          borderBottom: `1px solid ${palette.neutralQuaternary}`,
        },
      },
    },
  ],
  item: [
    classNames.item,
    {
      display: 'inline-block',
      position: 'relative',
      marginLeft: '5px',
    },
  ],
});

export const TaskStage: React.FC<ITaskStageProps> = ({ stage, status, allowRestart }) => (
  <div className={classNames.root}>
    <TooltipHost content="Подготовка отчета">
      <Icon
        className={classNames.item}
        {...getIconForStatus(
          resolveStatusForStage(TaskStageEnum.BUILD, status, stage),
          isDependOn(TaskStageEnum.BUILD, stage),
          allowRestart
        )}
      />
    </TooltipHost>
    <TooltipHost content="Загрузка отчета в GA">
      <Icon
        className={classNames.item}
        {...getIconForStatus(
          resolveStatusForStage(TaskStageEnum.UPLOAD, status, stage),
          isDependOn(TaskStageEnum.UPLOAD, stage),
          allowRestart
        )}
      />
    </TooltipHost>
    <TooltipHost content="Проверка обработки файла после загрузки">
      <Icon className={classNames.item} {...getIconForCheckStatus(stage, status, allowRestart)} />
    </TooltipHost>
    {stage === TaskStageEnum.DELETE ? (
      <TooltipHost content="Удаление загрузки">
        <Icon
          className={classNames.item}
          {...getIconForStatus(status, isDependOn(TaskStageEnum.DELETE, stage), allowRestart)}
        />
      </TooltipHost>
    ) : null}
  </div>
);

export default TaskStage;
