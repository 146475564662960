import React from 'react';
import { RelativeTime } from '@airmedia/react-time';
import parseISO from 'date-fns/parseISO';
import isFuture from 'date-fns/isFuture';
import { RestartInfo } from '../store';

export interface IRestartInfoProps {
  restart?: RestartInfo;
}

export const RestartStatus: React.FC<IRestartInfoProps> = ({ restart }) => {
  if (restart === undefined || !restart.enabled) {
    return null;
  }

  const restartAfter = parseISO(restart.after);

  if (isFuture(restartAfter)) {
    return (
      <span>
        Повторный запуск <RelativeTime date={restart.after} />
      </span>
    );
  }

  return <span>Задача находится в очереди</span>;
};

export default RestartStatus;
