import { schema } from 'normalizr';
import { camelizeKeys } from 'humps';
import { CallApiOptions } from '@airmedia/api-utils';
import { httpGet } from '../../utils/restApi';
import { IDataSourceListResponse } from './types';

export * from './types';

const datasourceSchema = new schema.Entity(
  'datasources',
  {},
  {
    processStrategy: (entity) => camelizeKeys(entity),
  }
);

export async function fetchDataSources(
  projectId: string,
  refresh: boolean = false
): Promise<IDataSourceListResponse> {
  let options: CallApiOptions = {
    schema: [datasourceSchema],
  };

  if (refresh) {
    options.headers = {
      'cache-control': 'no-cache',
    };
  }

  const result = await httpGet(`/api/${projectId}/datasources`, options);

  if (!result.payload) {
    throw new Error('Unknown result from API method');
  }

  return result.payload as IDataSourceListResponse;
}
