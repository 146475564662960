import React from 'react';
import { connect } from 'react-redux';
import Types from 'AppTypes';
import {
  EditStandardDataSetForm,
  EditStaticDataSetForm,
  IGenericFormProps,
} from '../components/EditDataSetForm';
import { datasetActions, datasetSelectors, DataSetType, IDataSet } from '../store';

type IDispatchProps = {
  fetchDataSet: typeof datasetActions.fetchDataSet;
  replaceDataSet: typeof datasetActions.replaceDataSet;
};

type IStateProps = {
  dataset?: IDataSet;
};

type IOwnProps = Pick<IGenericFormProps, 'projectId' | 'onUpdated' | 'onCloseDialog'> & {
  datasetId: number;
};

type IEditDataSetFormConnectedProps = IDispatchProps & IStateProps & IOwnProps;

const mapStateToProps = (state: Types.RootState, { datasetId }: IOwnProps): IStateProps => ({
  dataset: datasetSelectors.getDataSet(state, datasetId),
});

const mapDispatchToProps: IDispatchProps = {
  fetchDataSet: datasetActions.fetchDataSet,
  replaceDataSet: datasetActions.replaceDataSet,
};

export class EditDataSetFormDialog extends React.Component<IEditDataSetFormConnectedProps> {
  public componentDidMount(): void {
    const { projectId, datasetId, fetchDataSet } = this.props;
    fetchDataSet(projectId, datasetId);
  }

  private handleUpdated = (item: IDataSet) => {
    const { replaceDataSet, onUpdated } = this.props;
    replaceDataSet(item);
    onUpdated(item);
  };

  public render() {
    const { projectId, dataset, onCloseDialog } = this.props;

    if (!dataset || !dataset.reportOptions) {
      return null;
    }

    if (dataset.type === DataSetType.Standard) {
      return (
        <EditStandardDataSetForm
          projectId={projectId}
          dataset={dataset}
          onUpdated={this.handleUpdated}
          onCloseDialog={onCloseDialog}
        />
      );
    }

    if (dataset.type === DataSetType.Static) {
      return (
        <EditStaticDataSetForm
          projectId={projectId}
          dataset={dataset}
          onUpdated={this.handleUpdated}
          onCloseDialog={onCloseDialog}
        />
      );
    }

    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDataSetFormDialog);
