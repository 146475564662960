import TrackerQueue from 'autotrack/lib/tracker-queue';

/* global ga */

const TRACKERS_ALL = [{ name: 'prod', trackingId: 'UA-85979287-7' }];

/**
 * Creates a ga() proxy function that calls commands on all passed trackers.
 * @param {!Array} trackers an array or objects containing the `name` and
 *     `trackingId` fields.
 * @return {!Function} The proxied ga() function.
 */
const createGaProxy = (trackers) => (command, ...args) => {
  trackers.forEach(({ name, trackingId }) => {
    TrackerQueue.getOrCreate(trackingId).pushTask(() => {
      if (typeof command === 'function') {
        ga(() => {
          command(ga.getByName(name));
        });
      } else if (command === 'create') {
        ga('create', Object.assign({}, args[args.length - 1], { name, trackingId }));
      } else {
        ga(`${name}.${command}`, ...args);
      }
    });
  });
};

export const gaAll = createGaProxy(TRACKERS_ALL);

/**
 * Tracks a JavaScript error with optional fields object overrides.
 * This function is exported so it can be used in other parts of the codebase.
 * E.g.:
 *
 *    `fetch('/api.json').catch(trackError);`
 *
 * @param {*=} err
 * @param {FieldsObj=} fieldsObj
 */
export const trackError = (err = {}, fieldsObj = {}) => {
  gaAll('send', 'event', {
    eventCategory: 'Error',
    eventAction: err.name || '(no error name)',
    eventLabel: `${err.message}\n${err.stack || '(no stack trace)'}`,
    nonInteraction: true,
    ...fieldsObj,
  });
};

export const initializeGA = () => {
  import('./bootstrap').then(({ default: run }) => {
    run(gaAll, trackError);
  });
};
