export const UTM_CONTENT_PREFERRED_FORMAT =
  'ct:{campaign_type}|cid:{campaign_id}|gid:{gbid}|ad:{ad_id}|criteria:{phrase_id}|radjid:{coef_goal_context_id}|place:{source}|nt:{source_type}|dev:{device_type}|loc:{region_id}|slot:{position_type}';

export const REPORT_SCHEMA = {
  by_term:
    'ga:date,ga:medium,ga:source,ga:campaign,ga:keyword,ga:impressions,ga:adClicks,ga:adCost',
  by_content:
    'ga:date,ga:medium,ga:source,ga:campaign,ga:keyword,ga:adContent,ga:adClicks,ga:adCost',
};

export const REPORT_TYPE_BY_TERM = 'by_term';
export const REPORT_TYPE_BY_CONTENT = 'by_content';
