import React from 'react';
import { connect } from 'react-redux';
import DataSetPanelBase, { IDataSetPanelProps } from '../components/DataSetPanel';
import { datasetActions, IDataSet } from '../store';

type IDispatchProps = {
  loadDataset: typeof datasetActions.fetchDataSet;
};

export type IOwnProps = IDataSetPanelProps & {
  projectId: string;
  dataset: IDataSet;
};

const mapDispatchToProps: IDispatchProps = {
  loadDataset: datasetActions.fetchDataSet,
};

export type IDataSetPanelConnectedProps = IDispatchProps & IOwnProps;

export class DataSetPanel extends React.Component<IDataSetPanelConnectedProps> {
  public componentDidMount(): void {
    const { loadDataset, dataset, projectId } = this.props;

    if (!dataset.isFetching && dataset.dataSource === undefined) {
      loadDataset(projectId, dataset.id);
    }
  }

  public render() {
    const { dataset, onClose } = this.props;

    return <DataSetPanelBase dataset={dataset} onClose={onClose} />;
  }
}

export default connect(undefined, mapDispatchToProps)(DataSetPanel);
