import { combineReducers } from 'redux';
import datasetsReducer from './datasets';
import projectsReducer from './projects';
import sessionReducer from './session';
import tasksReducer from './tasks';
import viewReducer from './view';

export default combineReducers({
  datasets: datasetsReducer,
  projects: projectsReducer,
  session: sessionReducer,
  tasks: tasksReducer,
  view: viewReducer,
});
