import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import * as selectors from './selectors';
import { IViewState } from './types';
import { fetchDataSetsAsync, fetchDataSetAsync } from '../datasets/actions';
import { fetchProjectDetailsAsync } from '../projects/actions';
import { fetchTasksAsync } from '../tasks/actions';
import { isForbidden, isNotFound } from '../../utils/restApi';

export { actions as viewActions, selectors as viewSelectors };

const initialState: IViewState = {
  pageId: null,
  pageStatus: null,
};

type ViewActions = ActionType<typeof actions>;

const pageId = createReducer(initialState.pageId).handleAction(
  actions.setPageId,
  (state, { payload }) => payload.pageId
);

const pageStatus = createReducer(initialState.pageStatus)
  .handleAction(actions.setPageId, () => initialState.pageStatus)
  .handleAction(actions.setPageStatus, (state, { payload }) => {
    if (state !== initialState.pageStatus && !payload.rewrite) {
      return state;
    }

    return payload.status;
  })
  .handleAction(
    [
      fetchDataSetAsync.failure,
      fetchDataSetsAsync.failure,
      fetchProjectDetailsAsync.failure,
      fetchTasksAsync.failure,
    ],
    (state, { payload }) => {
      if (isNotFound(payload)) {
        return '404';
      }
      if (isForbidden(payload)) {
        return '403';
      }
      return state;
    }
  );

export default combineReducers<IViewState, ViewActions>({
  pageId,
  pageStatus,
});
