import { IDataSet, ITask, TaskStageEnum, TaskStatusEnum } from '../store';

export const canDelete = (task: ITask, dataset: IDataSet) => {
  if (!dataset.permissions || !dataset.permissions.edit) {
    return false;
  }

  if (task.stage === TaskStageEnum.UPLOAD && task.status === TaskStatusEnum.COMPLETED) {
    return true;
  }

  if (task.stage === TaskStageEnum.CHECK_STATUS && task.status !== TaskStatusEnum.RUNNING) {
    return true;
  }

  return false;
};

export const computeLoss = (inputValue: number, outputValue: number): [number, number] => {
  if (inputValue <= 0) {
    return [0, 0];
  }

  let loss = inputValue - outputValue;

  if (loss < 0.01) {
    loss = 0;
  }

  return [loss, 1 - outputValue / inputValue];
};

export const canBeRestarted = (task: ITask) => task.restart !== undefined && task.restart.enabled;

export const isResolvingUploadStatus = (stage: TaskStageEnum, status: TaskStatusEnum) =>
  stage === TaskStageEnum.CHECK_STATUS &&
  (status === TaskStatusEnum.PENDING || status === TaskStatusEnum.RUNNING);
