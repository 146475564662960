import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  FontSizes,
  IColumn,
  IStackItemStyles,
  Link,
  mergeStyles,
  Pivot,
  PivotItem,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
} from 'office-ui-fabric-react';
import { LongDate } from '@airmedia/react-time';
import { EmptyState } from '../EmptyState';
import { DataSetType, IDataSet, ReportType } from '../../store';
import { generatePathForRoute } from '../../utils/route';
import { translate as translateReportType } from '../../utils/reportType';
import { translate as translateDataSetType } from '../../utils/datasetType';
import ActionsMenu from './ActionsMenu';

export interface IDataSetListProps {
  projectId: string;
  datasets: IDataSet[];
  isFetching: boolean;
  onArchiveItemClick: (item: IDataSet) => void;
  onUnarchiveItemClick: (item: IDataSet) => void;
  onEditItemClick: (item: IDataSet) => void;
}

enum ListType {
  ARCHIVED = 'archived',
  ACTIVE = 'active',
}

const linkClass = mergeStyles({
  fontSize: FontSizes.medium,
});

const columns: IColumn[] = [
  {
    key: 'name',
    name: 'Название',
    minWidth: 200,
    maxWidth: 600,
    fieldName: 'name',
  },
  {
    key: 'type',
    name: 'Тип',
    minWidth: 100,
    maxWidth: 120,
    fieldName: 'type',
  },
  {
    key: 'reportType',
    name: 'Тип отчета',
    minWidth: 120,
    maxWidth: 270,
    fieldName: 'reportType',
  },
  {
    key: 'createdAt',
    name: 'Создан',
    minWidth: 120,
    fieldName: 'createdAt',
  },
];

const menuStackItemStyles: IStackItemStyles = {
  root: {
    margin: '-11px 0 -11px auto',
  },
};

export const DataSetList = (props: IDataSetListProps) => {
  const {
    datasets,
    isFetching,
    projectId,
    onArchiveItemClick,
    onUnarchiveItemClick,
    onEditItemClick,
  } = props;
  const activeDatasets = React.useMemo(() => datasets.filter((item) => !item.archived), [datasets]);
  const archivedDatasets = React.useMemo(() => datasets.filter((item) => item.archived), [
    datasets,
  ]);
  const [selectedKey, setSelectedKey] = React.useState<string | undefined>(
    activeDatasets.length === 0 && archivedDatasets.length > 0 ? ListType.ARCHIVED : ListType.ACTIVE
  );
  const handlePivotLinkClick = React.useCallback(
    (item?: PivotItem): void => {
      if (!item) {
        setSelectedKey(ListType.ACTIVE);
      } else {
        setSelectedKey(item.props.itemKey);
      }
    },
    [setSelectedKey]
  );
  const onRenderItemColumn = React.useCallback(
    (item?: IDataSet, index?: number, column?: IColumn) => {
      if (!item || !column) {
        return;
      }

      const fieldContent = item[column.fieldName as keyof IDataSet];
      const href = generatePathForRoute('dataset', {
        project: projectId,
        dataset: item.id,
      });

      switch (column.key) {
        case 'name':
          return (
            <Stack horizontal>
              <Stack.Item grow>
                <Link as={RouterLink as any} to={href} className={linkClass}>
                  {fieldContent}
                </Link>
              </Stack.Item>
              <Stack.Item styles={menuStackItemStyles}>
                <ActionsMenu
                  dataset={item}
                  onEditItemClick={onEditItemClick}
                  onArchiveItemClick={onArchiveItemClick}
                  onUnarchiveItemClick={onUnarchiveItemClick}
                />
              </Stack.Item>
            </Stack>
          );
        case 'createdAt':
          return <LongDate date={fieldContent as string} />;
        case 'reportType':
          return translateReportType(fieldContent as ReportType);
        case 'type':
          return translateDataSetType(fieldContent as DataSetType);
      }

      return fieldContent;
    },
    [projectId, onArchiveItemClick, onUnarchiveItemClick, onEditItemClick]
  );

  if (!isFetching && datasets.length === 0) {
    return (
      <EmptyState
        primaryMessage="Нет данных"
        secondaryMessage="Вы можете создать новый импорт данных прямо сейчас."
      />
    );
  }

  const items = selectedKey === 'archived' ? archivedDatasets : activeDatasets;

  return (
    <>
      <Pivot headersOnly selectedKey={selectedKey} onLinkClick={handlePivotLinkClick}>
        <PivotItem
          headerText="Активные"
          itemCount={activeDatasets.length}
          itemKey={ListType.ACTIVE}
        />
        <PivotItem
          headerText="В архиве"
          itemCount={archivedDatasets.length}
          itemKey={ListType.ARCHIVED}
        />
      </Pivot>
      {isFetching || items.length > 0 ? (
        <ShimmeredDetailsList
          items={items}
          columns={columns}
          selectionMode={SelectionMode.none}
          enableShimmer={isFetching}
          ariaLabelForShimmer="Данные загружаются"
          onRenderItemColumn={onRenderItemColumn}
        />
      ) : (
        <EmptyState primaryMessage="Список пуст" />
      )}
    </>
  );
};

export default DataSetList;
