import { createAsyncAction, createAction } from 'typesafe-actions';
import { FetchProjectDetailsActionTypes, FetchProjectsActionTypes } from './types';
import { IProjectDetailsResponse, IProjectListResponse } from '../../api/projects';

export const fetchProjects = (reset: boolean = false) => fetchProjectsAsync.request(reset);
export const fetchProjectDetails = (projectId: string) =>
  fetchProjectDetailsAsync.request(projectId);

// Internal actions

export const fetchProjectsAsync = createAsyncAction(
  FetchProjectsActionTypes.REQUEST,
  FetchProjectsActionTypes.SUCCESS,
  FetchProjectsActionTypes.FAILURE
)<boolean, IProjectListResponse, Error>();

export const fetchProjectDetailsAsync = {
  request: createAction(FetchProjectDetailsActionTypes.REQUEST)<string>(),
  success: createAction(FetchProjectDetailsActionTypes.SUCCESS)<IProjectDetailsResponse>(),
  failure: createAction(FetchProjectDetailsActionTypes.FAILURE)<
    Error,
    {
      projectId: string;
    }
  >(),
};
