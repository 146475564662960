import React from 'react';
import { Redirect } from 'react-router';
import * as H from 'history';
import { IStackStyles, IStackTokens, Link, Stack } from 'office-ui-fabric-react';
import SignInForm from '../../components/SignInForm';
import logo from '../../logo.svg';

export interface IAuthPageProps {
  location: H.Location<{
    from?: H.LocationDescriptorObject;
  } | null>;
}

const stackStyles: IStackStyles = {
  root: {
    maxWidth: 340,
    height: '100vh',
    margin: '0 auto',
    padding: '25px',
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 25,
};

const headerStackTokens: IStackTokens = {
  childrenGap: 15,
};

export const AuthPage = ({ location }: IAuthPageProps) => {
  const [isAuthorized, setAuthorized] = React.useState<boolean>(false);

  if (isAuthorized) {
    const { from } = location.state || { from: { pathname: '/' } };
    return <Redirect to={from as H.LocationDescriptorObject} />;
  }

  return (
    <Stack verticalAlign="center" verticalFill styles={stackStyles} tokens={stackTokens}>
      <Stack.Item>
        <Stack horizontal verticalAlign="center" tokens={headerStackTokens}>
          <Stack.Item>
            <img src={logo} alt="Logo" width={48} height={48} />
          </Stack.Item>
          <Stack.Item>
            <h2>Импорт расходов</h2>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <SignInForm
          onAuthorized={() => {
            setAuthorized(true);
          }}
        />
      </Stack.Item>
      <Stack.Item>
        Нет учетной записи? <Link href="https://users.airmediapro.ru">Создайте её!</Link>
      </Stack.Item>
    </Stack>
  );
};

export default AuthPage;
