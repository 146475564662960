import { createAsyncAction, createAction } from 'typesafe-actions';
import { IDataSetDetailsResponse, IDataSetListResponse } from '../../api/datasets';
import { IDataSet } from './models';
import {
  ArchiveDataSetActionTypes,
  FetchDataSetActionTypes,
  FetchDataSetsActionTypes,
  UnarchiveDataSetActionTypes,
} from './types';

export const fetchDataSets = (projectId: string) => fetchDataSetsAsync.request(projectId);
export const fetchDataSet = (projectId: string, datasetId: number) =>
  fetchDataSetAsync.request({
    projectId,
    datasetId,
  });
export const addDataSet = createAction('datasets.item.ADD')<IDataSet>();
export const archiveDataSet = (projectId: string, datasetId: number) =>
  archiveDataSetAsync.request({
    projectId,
    datasetId,
  });
export const unarchiveDataSet = (projectId: string, datasetId: number) =>
  unarchiveDataSetAsync.request({
    projectId,
    datasetId,
  });
export const replaceDataSet = createAction('dataset.item.REPLACE')<IDataSet>();

// Internal actions

export const fetchDataSetsAsync = createAsyncAction(
  FetchDataSetsActionTypes.REQUEST,
  FetchDataSetsActionTypes.SUCCESS,
  FetchDataSetsActionTypes.FAILURE
)<string, IDataSetListResponse, Error>();

export const fetchDataSetAsync = {
  request: createAction(FetchDataSetActionTypes.REQUEST)<{
    projectId: string;
    datasetId: number;
  }>(),
  success: createAction(FetchDataSetActionTypes.SUCCESS)<
    IDataSetDetailsResponse,
    {
      projectId: string;
    }
  >(),
  failure: createAction(FetchDataSetActionTypes.FAILURE)<
    Error,
    {
      projectId: string;
      datasetId: number;
    }
  >(),
};

export const archiveDataSetAsync = {
  request: createAction(ArchiveDataSetActionTypes.REQUEST)<{
    projectId: string;
    datasetId: number;
  }>(),
  success: createAction(ArchiveDataSetActionTypes.SUCCESS)<{
    projectId: string;
    datasetId: number;
  }>(),
  failure: createAction(ArchiveDataSetActionTypes.FAILURE)<
    Error,
    {
      projectId: string;
      datasetId: number;
    }
  >(),
};

export const unarchiveDataSetAsync = {
  request: createAction(UnarchiveDataSetActionTypes.REQUEST)<{
    projectId: string;
    datasetId: number;
  }>(),
  success: createAction(UnarchiveDataSetActionTypes.SUCCESS)<{
    projectId: string;
    datasetId: number;
  }>(),
  failure: createAction(UnarchiveDataSetActionTypes.FAILURE)<
    Error,
    {
      projectId: string;
      datasetId: number;
    }
  >(),
};
