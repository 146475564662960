import React from 'react';
import { StandardReportOptions, StaticReportOptions } from '../../store';
import DescriptionList from '../DescriptionList';

export interface IReportOptionsDescriptionListProps {
  options: StandardReportOptions | StaticReportOptions;
}

const renderStandardReportOptions = (options: StandardReportOptions) => (
  <DescriptionList>
    <dt>Расход с учетом НДС</dt>
    <dd>{options.includeVat ? 'Да' : 'Нет'}</dd>
    <dt>Фильтр по домену</dt>
    {options.allowedDomains && options.allowedDomains.length > 0 ? (
      options.allowedDomains.map((pattern) => <dd key={pattern}>{pattern}</dd>)
    ) : (
      <dd>—</dd>
    )}
    <dt>Импортировать статистику по Динамическим объявлениям</dt>
    <dd>{options.dynamicTextAdEnabled ? 'Да' : 'Нет'}</dd>
    <dt>Импортировать статистику по Смарт-баннерам</dt>
    <dd>{options.smartAdEnabled ? 'Да' : 'Нет'}</dd>
  </DescriptionList>
);

const renderStaticReportOptions = (options: StaticReportOptions) => (
  <DescriptionList>
    <dt>Расход с учетом НДС</dt>
    <dd>{options.includeVat ? 'Да' : 'Нет'}</dd>
    <dt>utm_source</dt>
    <dd>{options.utmSource}</dd>
    <dt>utm_medium</dt>
    <dd>{options.utmMedium}</dd>
    <dt>utm_campaign</dt>
    <dd>{options.utmCampaign}</dd>
    <dt>utm_term</dt>
    <dd>{options.utmTerm}</dd>
    <dt>utm_content</dt>
    <dd>{options.utmContent || '—'}</dd>
  </DescriptionList>
);

export const ReportOptionsDescriptionList = ({ options }: IReportOptionsDescriptionListProps) => {
  if ((options as StandardReportOptions).allowedDomains !== undefined) {
    return renderStandardReportOptions(options as StandardReportOptions);
  }

  if ((options as StaticReportOptions).utmSource !== undefined) {
    return renderStaticReportOptions(options as StaticReportOptions);
  }

  return null;
};

export default ReportOptionsDescriptionList;
