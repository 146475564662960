import React from 'react';
import {
  CheckboxVisibility,
  IColumn,
  ISelection,
  mergeStyles,
  ShimmeredDetailsList,
} from 'office-ui-fabric-react';
import { RelativeTime } from '@airmedia/react-time';
import { EmptyState } from '../EmptyState';
import TaskStage from './TaskStage';
import StatsValue from './StatsValue';
import TaskStatus from '../TaskStatus';
import UploadStatus from '../UploadStatus';
import { ITask, TaskStageEnum } from '../../store';
import { formatLocaleDate } from '../../utils/date';

export interface ITaskListProps {
  tasks: ITask[];
  isFetching: boolean;
  selection: ISelection;
}

const uploadStatusClassName = mergeStyles({
  display: 'block',
});

function buildColumns(): IColumn[] {
  return [
    {
      key: 'id',
      name: '№',
      minWidth: 50,
      maxWidth: 90,
      fieldName: 'id',
    },
    {
      key: 'reportDate',
      name: 'Дата выгружаемых данных',
      minWidth: 120,
      maxWidth: 200,
      onRender: (item: ITask) => formatLocaleDate(item.reportDate),
    },
    {
      key: 'stage',
      name: '',
      minWidth: 80,
      maxWidth: 80,
      onRender: (item: ITask) => (
        <TaskStage
          stage={item.stage}
          status={item.status}
          allowRestart={item.restart && item.restart.enabled}
        />
      ),
    },
    {
      key: 'status',
      name: 'Статус',
      minWidth: 80,
      maxWidth: 150,
      onRender: (item: ITask) => <TaskStatus stage={item.stage} status={item.status} />,
    },
    {
      key: 'impressions',
      name: 'Показы',
      minWidth: 60,
      maxWidth: 90,
      onRender: ({ fetchStats, fileStats }: ITask) => {
        if (fetchStats && fileStats) {
          return <StatsValue value={fetchStats.impressions} outValue={fileStats.impressions} />;
        }

        return null;
      },
    },
    {
      key: 'clicks',
      name: 'Клики',
      minWidth: 60,
      maxWidth: 90,
      onRender: ({ fetchStats, fileStats }: ITask) => {
        if (fetchStats && fileStats) {
          return <StatsValue value={fetchStats.clicks} outValue={fileStats.clicks} />;
        }

        return null;
      },
    },
    {
      key: 'cost',
      name: 'Расход',
      minWidth: 80,
      maxWidth: 120,
      onRender: ({ fetchStats, fileStats }: ITask) => {
        if (fetchStats && fileStats) {
          return <StatsValue value={fetchStats.cost} outValue={fileStats.cost} decimals={2} />;
        }

        return null;
      },
    },
    {
      key: 'uploadInfo',
      name: 'Загрузка',
      minWidth: 200,
      onRender: (item: ITask) => {
        if (item.stage === TaskStageEnum.BUILD || !item.upload) {
          return null;
        }

        return (
          <>
            <UploadStatus className={uploadStatusClassName} status={item.upload.status} />
            <RelativeTime date={item.upload.statusUpdatedAt} />
          </>
        );
      },
    },
  ];
}

export const TaskList: React.FC<ITaskListProps> = ({ isFetching, tasks, selection }) => {
  const columns = React.useMemo(buildColumns, []);

  if (!isFetching && tasks.length === 0) {
    return (
      <EmptyState
        primaryMessage="Список пуст"
        secondaryMessage="Новые задачи будут появляться каждый день, пока импорт данных активен."
      />
    );
  }

  return (
    <ShimmeredDetailsList
      items={tasks}
      columns={columns}
      selection={selection}
      checkboxVisibility={CheckboxVisibility.hidden}
      enableShimmer={isFetching && tasks.length === 0}
      ariaLabelForShimmer="Данные загружаются"
    />
  );
};

export default TaskList;
