import React from 'react';
import { Route, RouteChildrenProps, Switch } from 'react-router';
import { Fabric, mergeStyles } from 'office-ui-fabric-react';
import routes, { IRouteProps, AUTH_PATH } from './routes';
import Header from './components/Header';
import EnhancedRoute from './components/EnhancedRoute';

mergeStyles({
  selectors: {
    ':global(html), :global(body)': {
      margin: 0,
      padding: 0,
    },
  },
});

const App = () => (
  <Fabric>
    <Route
      path={AUTH_PATH}
      children={({ match }: RouteChildrenProps) => {
        if (match) {
          return null;
        }
        return <Header />;
      }}
    />
    <Switch>
      {routes.map((props: IRouteProps, index: number) => (
        <EnhancedRoute key={index} {...props} />
      ))}
    </Switch>
  </Fabric>
);

export default App;
