import React from 'react';
import { useHistory } from 'react-router-dom';
import { DefaultButton } from 'office-ui-fabric-react';
import Page, { IPageStyles } from '../../components/Page';
import searchImg from '../../assets/search.svg';

const pageStyles: IPageStyles = {
  main: {
    margin: '0 auto',
    maxWidth: 960,
    minHeight: 'calc(100vh - 156px)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '480px',
    backgroundPosition: '80% 80%',
    backgroundImage: `url(${searchImg})`,
  },
};

export const NotFoundPage = () => {
  const history = useHistory();
  const onGoBack = React.useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Page styles={pageStyles}>
      <h1>Страница не найдена</h1>
      <p>Адрес страницы может быть написан с ошибкой или страница больше недоступна.</p>
      {history.length > 1 && (
        <p>
          <DefaultButton onClick={onGoBack}>Назад</DefaultButton>
        </p>
      )}
    </Page>
  );
};

export default NotFoundPage;
