import { combineReducers } from 'redux';
import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './actions';
import * as selectors from './selectors';
import { ISessionState } from './types';

export { actions as sessionActions, selectors as sessionSelectors };
export * from './types';

const initialState: ISessionState = {
  authenticated: false,
  profile: null,
};

type SessionAction = ActionType<typeof actions>;

const authenticated = createReducer(initialState.authenticated)
  .handleAction(actions.authenticated, () => true)
  .handleAction(actions.signout, () => false);

const profile = createReducer(initialState.profile)
  .handleAction(actions.fetchProfileAsync.success, (state, { payload }) => payload)
  .handleAction(actions.signout, () => initialState.profile);

export default combineReducers<ISessionState, SessionAction>({
  authenticated,
  profile,
});
