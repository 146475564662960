import * as yup from 'yup';
import { IGenericFormProps } from '../types';
import { StaticReportOptions } from '../../../store';

export const createStandardSchema = (): yup.ObjectSchema =>
  yup.object({
    name: yup.string().required(),
  });

export const createStaticSchema = ({ dataset }: IGenericFormProps): yup.ObjectSchema => {
  const { reportOptions } = dataset;

  let schema = yup.object({
    name: yup.string().required(),
    utmSource: yup.string().required(),
    utmMedium: yup.string().required(),
    utmCampaign: yup.string().required(),
    utmTerm: yup.string().required(),
  });

  if (typeof (reportOptions as StaticReportOptions).utmContent === 'string') {
    schema = schema.concat(
      yup.object({
        utmContent: yup.string().required(),
      })
    );
  }

  return schema;
};
