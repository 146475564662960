import { createAction, createAsyncAction } from 'typesafe-actions';
import { FetchProfileActionTypes, IProfile } from './types';

export const authenticated = createAction('session.AUTHENTICATED')();

export const signout = createAction('session.SIGNOUT')();

export const fetchProfile = () => fetchProfileAsync.request();

// Internal actions

export const fetchProfileAsync = createAsyncAction(
  FetchProfileActionTypes.REQUEST,
  FetchProfileActionTypes.SUCCESS,
  FetchProfileActionTypes.FAILURE
)<undefined, IProfile, Error>();
