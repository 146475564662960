import { FormikBag, withFormik } from 'formik';
import { arraysEqual } from 'office-ui-fabric-react';
import * as api from '../../../api';
import { IUpdateDataSet, StandardReportOptions } from '../../../api';
import EditDataSetForm from './Form';
import { IFormValues, IGenericFormProps } from './types';
import { handleError } from '../../../utils/formik';
import { createStandardSchema } from '../utils/validation';

export async function handleSubmit(
  values: IFormValues,
  formBag: FormikBag<IGenericFormProps, IFormValues>
) {
  const {
    setStatus,
    props: { projectId, dataset, onUpdated },
  } = formBag;

  try {
    const reportOptions = dataset.reportOptions || {
      allowedDomains: [],
      includeVat: false,
    };
    const payload: IUpdateDataSet = {};

    if (dataset.name !== values.name) {
      payload['name'] = values.name;
    }

    const currentDomainsFilter =
      (dataset.reportOptions as StandardReportOptions).allowedDomains || [];
    if (
      !arraysEqual(currentDomainsFilter, values.allowedDomains) ||
      reportOptions.includeVat !== values.includeVat
    ) {
      payload['reportOptions'] = {
        allowedDomains: values.allowedDomains.length > 0 ? values.allowedDomains : null,
        includeVat: values.includeVat,
        dynamicTextAdEnabled: values.importDynamicTextAd,
        smartAdEnabled: values.importSmartAd,
      };
    }

    if (Object.keys(payload).length > 0) {
      const response = await api.updateDataSet(projectId, dataset.id, payload);

      setStatus(null);
      onUpdated(response.entities.datasets[dataset.id]);
    } else {
      setStatus(null);
      onUpdated(dataset);
    }
  } catch (e) {
    handleError(e, values, formBag);
  }
}

export default withFormik<IGenericFormProps, IFormValues>({
  handleSubmit,
  validateOnBlur: false,
  validationSchema: createStandardSchema(),
  mapPropsToValues: ({ dataset }) => {
    const { name, reportOptions } = dataset;
    return {
      name,
      includeVat: !!(undefined !== reportOptions && reportOptions.includeVat),
      allowedDomains: (reportOptions as StandardReportOptions).allowedDomains || [],
      importDynamicTextAd: (reportOptions as StandardReportOptions).dynamicTextAdEnabled,
      importSmartAd: (reportOptions as StandardReportOptions).smartAdEnabled,
    };
  },
})(EditDataSetForm);
