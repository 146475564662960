import React from 'react';
import Page, { IPageStyles } from '../../components/Page';
import ProjectList from '../../connected/ProjectList';

const pageStyles: IPageStyles = {
  main: {
    maxWidth: 960,
    margin: '0 auto',
  },
};

export const MainPage = () => (
  <Page styles={pageStyles}>
    <ProjectList />
  </Page>
);

export default MainPage;
