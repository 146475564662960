import React from 'react';
import { connect } from 'react-redux';
import Types from 'AppTypes';
import { Spinner } from 'office-ui-fabric-react';
import ProjectListView, { IProjectListProps } from '../components/ProjectList';
import { EmptyState } from '../components/EmptyState';
import { projectActions, projectSelectors } from '../store';
import { withInfiniteScroll } from '../hoc/withInfiniteScroll';

type IStateProps = {
  projects: IProjectListProps['projects'];
  isFetching: boolean;
  total: number;
};

type IDispatchProps = {
  loadProjects: typeof projectActions.fetchProjects;
};

type IProjectListConnectedProps = IStateProps & IDispatchProps;

const ProjectListInfiniteScroll = withInfiniteScroll<IProjectListProps>(ProjectListView);

const mapStateToProps = (state: Types.RootState): IStateProps => ({
  projects: projectSelectors.getProjects(state),
  isFetching: projectSelectors.isFetching(state),
  total: projectSelectors.getTotal(state),
});

const mapDispatchToProps: IDispatchProps = {
  loadProjects: projectActions.fetchProjects,
};

export class ProjectList extends React.Component<IProjectListConnectedProps> {
  public componentDidMount(): void {
    const { projects, loadProjects } = this.props;
    if (!projects.length) {
      loadProjects();
    }
  }

  private onLoadChunk = () => {
    this.props.loadProjects();
  };

  public render() {
    const { projects, isFetching, total } = this.props;
    const trackScroll = !isFetching && projects.length < total;
    return (
      <>
        {projects.length > 0 && (
          <ProjectListInfiniteScroll
            loadChunk={this.onLoadChunk}
            trackScroll={trackScroll}
            projects={projects}
          />
        )}
        {!isFetching && projects.length === 0 && <EmptyState primaryMessage="У вас нет проектов" />}
        {isFetching && <Spinner label="Пожалуйста, подождите..." labelPosition="right" />}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
