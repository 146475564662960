import { generatePath } from 'react-router';
import routes, { IRouteProps } from '../routes';

type RouteParams = {
  [paramName: string]: string | number | boolean | undefined;
};

const findRouteByKey = (routes: IRouteProps[], key: string): IRouteProps | undefined => {
  for (let route of routes) {
    if (route.key === key) {
      return route;
    }
    if (route.routes !== undefined) {
      let child = findRouteByKey(route.routes, key);
      if (child !== undefined) {
        return child;
      }
    }
  }
  return undefined;
};

export function generatePathForRoute(routeKey: string, params?: RouteParams): string {
  const route = findRouteByKey(routes, routeKey);

  if (!route) {
    throw new Error(`Not found route by key: ${routeKey}`);
  }

  if (typeof route.path !== 'string') {
    throw new Error(`Could not generate path for route with key: ${routeKey}`);
  }

  return generatePath(route.path, params);
}
