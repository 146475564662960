import { ReportType } from '../store';

type IReportTypeTrans = {
  [key in ReportType]: string;
};

const reportTypeTrans: IReportTypeTrans = {
  by_term: 'Группировка расходов по utm_term',
  by_content: 'Группировка расходов по utm_content',
};

export const translate = (value: ReportType): string => reportTypeTrans[value] || value;
