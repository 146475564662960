import React from 'react';
import {
  getTheme,
  IconButton,
  IIconProps,
  IStackTokens,
  Label,
  mergeStyles,
  Stack,
  TextField,
} from 'office-ui-fabric-react';
import { isDomainPattern } from '../../utils/validators';
import { InfoTooltip } from '../InfoTooltip';

export interface IDomainFilterProps {
  values: string[];
  onChange: (value: string[]) => void;
  isSubmitting: boolean;
}

type IFilterItemProps = {
  value: string;
  onDeleteClick: (value: string) => void;
  isSubmitting: boolean;
};

export const validateFilter = (value: string): string | undefined => {
  if (value !== '' && !isDomainPattern(value)) {
    return 'Значение недопустимо';
  }
};

const palette = getTheme().palette;

const stackTokens: IStackTokens = {
  childrenGap: 15,
};

const addIconProps: IIconProps = {
  iconName: 'Add',
};

const deleteIconProps: IIconProps = {
  iconName: 'Remove',
  styles: {
    root: {
      color: palette.redDark,
    },
  },
};

const emptyFilterClass = mergeStyles({
  color: palette.neutralTertiary,
});

const itemTitleClass = mergeStyles({
  paddingLeft: 15,
});

const FilterItem = ({ value, onDeleteClick, isSubmitting }: IFilterItemProps) => (
  <Stack horizontal verticalAlign="center" tokens={stackTokens}>
    <Stack.Item grow className={itemTitleClass}>
      {value}
    </Stack.Item>
    <Stack.Item>
      <IconButton
        disabled={isSubmitting}
        iconProps={deleteIconProps}
        onClick={() => {
          onDeleteClick(value);
        }}
      />
    </Stack.Item>
  </Stack>
);

export const DomainFilter = ({ values, onChange, isSubmitting }: IDomainFilterProps) => {
  const [newItem, setNewItem] = React.useState('');
  const addItem = React.useCallback(() => {
    const newItemLower = newItem.toLowerCase();
    if (!isDomainPattern(newItemLower)) {
      return;
    }
    if (values.find((v) => v === newItemLower) === undefined) {
      onChange(values.concat(newItemLower));
    }
    setNewItem('');
  }, [newItem, values, onChange]);
  const removeItem = React.useCallback(
    (value: string) => {
      onChange(values.filter((v) => v !== value));
    },
    [values, onChange]
  );
  const handleNewItemChange = React.useCallback(
    (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
      if (value !== undefined) {
        setNewItem(value);
      }
    },
    [setNewItem]
  );

  return (
    <>
      <Label>
        Фильтр по домену
        <InfoTooltip>
          <>
            Фильтр позволяет выбрать только те объявления, где домен соответствует хотя бы одному
            шаблону. Статистика по остальным объявлениям будет проигнорирована.
            <br />
            <br />
            Один шаблон должен содержать полное имя домена, без www. Так же возможно использование
            маски для поддомена.
            <br />
            Например, шаблон <code>*.domain.ltd</code> будет соответствовать всем доменам третьего
            уровня в <code>domain.ltd</code>.
          </>
        </InfoTooltip>
      </Label>
      {values.length > 0 ? (
        values.map((value) => (
          <FilterItem
            key={value}
            value={value}
            onDeleteClick={removeItem}
            isSubmitting={isSubmitting}
          />
        ))
      ) : (
        <p className={emptyFilterClass}>Фильтр не задан</p>
      )}
      <Stack horizontal tokens={stackTokens}>
        <Stack.Item grow>
          <TextField
            disabled={isSubmitting}
            placeholder="Введите шаблон для фильтра"
            value={newItem}
            onChange={handleNewItemChange}
            onGetErrorMessage={validateFilter}
            validateOnFocusOut
          />
        </Stack.Item>
        <Stack.Item>
          <IconButton
            disabled={newItem === '' || isSubmitting}
            iconProps={addIconProps}
            ariaLabel="Добавить фильтр"
            onClick={addItem}
          />
        </Stack.Item>
      </Stack>
    </>
  );
};

export default DomainFilter;
