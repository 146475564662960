import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLeading, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { fetchProfile, ProfileResponse, signOut } from '@airmedia/auth';
import * as actions from './actions';
import rollbar from '../../rollbar';

function* loadProfile(): SagaIterator {
  try {
    const profile = yield call(fetchProfile);
    yield put(actions.fetchProfileAsync.success(profile as ProfileResponse));
  } catch (e) {
    yield put(actions.fetchProfileAsync.failure(e));
  }
}

function* signout(): SagaIterator {
  try {
    yield call(signOut);
  } catch (e) {
    rollbar.critical('Occurred an error during sign out', e);
  }
}

export function* sessionSagas() {
  /* eslint-disable redux-saga/no-unhandled-errors */
  yield all([
    takeLeading(getType(actions.fetchProfileAsync.request), loadProfile),
    takeEvery(getType(actions.signout), signout),
  ]);
  /* eslint-enable redux-saga/no-unhandled-errors */
}
