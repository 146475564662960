import { IContextualMenuItem } from 'office-ui-fabric-react';
import { IDataSet } from '../store';

type ItemCallback = (dataset: IDataSet) => void;

export function createMenuItems(
  dataset: IDataSet,
  onArchive: ItemCallback,
  onUnrachive: ItemCallback,
  onEdit: ItemCallback
): IContextualMenuItem[] {
  const items: IContextualMenuItem[] = [];

  if (dataset.archived) {
    items.push({
      key: 'unarchive',
      name: 'Извлечь из архива',
      ariaLabel: 'Извлечь из архива',
      iconProps: {
        iconName: 'Undo',
      },
      disabled: !dataset.permissions.unarchive,
      onClick: () => onUnrachive(dataset),
    });
  } else {
    items.push({
      key: 'edit',
      name: 'Редактировать',
      ariaLabel: 'Редактировать импорт данных',
      iconProps: {
        iconName: 'Edit',
      },
      disabled: !dataset.permissions.edit,
      onClick: () => onEdit(dataset),
    });
    items.push({
      key: 'archive',
      name: 'Добавить в архив',
      ariaLabel: 'Добавить импорт данных в архив',
      title:
        'Данные будут доступны только для чтения. Нельзя создать или удалить задачу, пока набор данных находится в архиве.\nВ Google Analytics данные не удаляются.',
      iconProps: {
        iconName: 'Archive',
      },
      disabled: !dataset.permissions.archive,
      onClick: () => onArchive(dataset),
    });
  }

  return items;
}
