import {
  getId,
  ICalloutProps,
  Icon,
  IIconStyles,
  ITooltipHostProps,
  ITooltipHostStyles,
  TooltipHost,
} from 'office-ui-fabric-react';
import React from 'react';

const tooltipHostStyles: ITooltipHostStyles = {
  root: {
    display: 'inline-block',
    zIndex: 1,
    cursor: 'pointer',
    marginLeft: 5,
  },
};
const iconStyles: IIconStyles = {
  root: {
    verticalAlign: 'middle',
    marginTop: -2,
  },
};
const calloutProps: ICalloutProps = {
  gapSpace: 0,
};

export type IInlineTooltipProps = {
  children: ITooltipHostProps['content'];
};

export const InfoTooltip = ({ children }: IInlineTooltipProps) => {
  const hostId = getId('tooltip-');

  return (
    <TooltipHost
      id={hostId}
      calloutProps={calloutProps}
      styles={tooltipHostStyles}
      content={children}
    >
      <Icon iconName="Info" styles={iconStyles} aria-describedby={hostId} ariaLabel="Info" />
    </TooltipHost>
  );
};
