import React from 'react';
import { connect } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { Dispatch } from 'redux';
import Types from 'AppTypes';
import {
  IGenericFormProps,
  NewStandardDataSetForm,
  NewStaticDataSetForm,
} from '../components/NewDataSetForm';
import * as api from '../api';
import { datasetActions, DataSetType, IDataSet } from '../store';

export type INewDataSetFormDialogProps = Pick<
  IGenericFormProps,
  'projectId' | 'onCloseDialog' | 'onCreated'
> & {
  datasetType: DataSetType;
};

const mapDispatchToProps = (
  dispatch: Dispatch<Types.RootAction>,
  { onCreated }: IGenericFormProps
) => ({
  onCreated: (dataset: IDataSet) => {
    dispatch(datasetActions.addDataSet(dataset));
    onCreated(dataset);
  },
});

export const NewDataSetFormDialog: React.FC<INewDataSetFormDialogProps> = ({
  projectId,
  datasetType,
  onCreated,
  onCloseDialog,
}) => {
  const [dataSourcesState, fetchDataSources] = useAsyncFn(async () => {
    const response = await api.fetchDataSources(projectId, true);
    return Object.values(response.entities.datasources);
  }, [projectId]);

  if (!dataSourcesState.loading && !dataSourcesState.value && !dataSourcesState.error) {
    fetchDataSources();
  }

  if (datasetType === DataSetType.Standard) {
    return (
      <NewStandardDataSetForm
        projectId={projectId}
        dataSources={dataSourcesState.value || []}
        onCloseDialog={onCloseDialog}
        onCreated={onCreated}
      />
    );
  }

  if (datasetType === DataSetType.Static) {
    return (
      <NewStaticDataSetForm
        projectId={projectId}
        dataSources={dataSourcesState.value || []}
        onCloseDialog={onCloseDialog}
        onCreated={onCreated}
      />
    );
  }

  return null;
};

export default connect<{}, {}, INewDataSetFormDialogProps>(
  null,
  mapDispatchToProps
)(NewDataSetFormDialog);
