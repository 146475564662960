import React from 'react';
import { Field, Form } from 'formik';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  IStackTokens,
  Label,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
} from 'office-ui-fabric-react';
import { FormikCheckbox, FormikTextField } from 'formik-office-ui-fabric-react';
import { IStaticFormProps } from './types';
import { translate } from '../../../utils/reportType';

const stackTokens: IStackTokens = {
  childrenGap: 15,
};

export const StaticForm: React.FC<IStaticFormProps> = ({
  isSubmitting,
  status,
  resetForm,
  onCloseDialog,
  dataset,
}) => {
  const closeDialog = React.useCallback(() => {
    resetForm();
    onCloseDialog();
  }, [resetForm, onCloseDialog]);

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: 'Редактирование',
        subText: `Импорт данных: ${dataset.name}`,
        showCloseButton: true,
      }}
      modalProps={{
        isBlocking: true,
      }}
      minWidth={480}
      onDismiss={isSubmitting ? undefined : closeDialog}
    >
      <Form>
        <Stack tokens={stackTokens}>
          <Stack.Item>
            <Field
              name="name"
              label="Название"
              required
              disabled={isSubmitting}
              component={FormikTextField}
            />
          </Stack.Item>
          <Stack.Item>
            <Label>Тип отчета</Label>
            <div>{translate(dataset.reportType)}</div>
          </Stack.Item>
          <Stack.Item>
            <Field name="includeVat" label="Расход с учетом НДС" component={FormikCheckbox} />
          </Stack.Item>
          <Stack.Item>
            <Field
              name="utmSource"
              label="utm_source"
              required
              disabled={isSubmitting}
              component={FormikTextField}
            />
          </Stack.Item>
          <Stack.Item>
            <Field
              name="utmMedium"
              label="utm_medium"
              required
              disabled={isSubmitting}
              component={FormikTextField}
            />
          </Stack.Item>
          <Stack.Item>
            <Field
              name="utmCampaign"
              label="utm_campaign"
              required
              disabled={isSubmitting}
              component={FormikTextField}
            />
          </Stack.Item>
          <Stack.Item>
            <Field
              name="utmTerm"
              label="utm_term"
              required
              disabled={isSubmitting}
              component={FormikTextField}
            />
          </Stack.Item>
          {dataset.reportType === 'by_content' && (
            <Stack.Item>
              <Field
                name="utmContent"
                label="utm_content"
                required
                disabled={isSubmitting}
                component={FormikTextField}
              />
            </Stack.Item>
          )}
          {status && <MessageBar messageBarType={MessageBarType.error}>{status}</MessageBar>}
        </Stack>
        <DialogFooter>
          <PrimaryButton type="submit" text="Сохранить" disabled={isSubmitting} />
          <DefaultButton
            type="button"
            text="Отмена"
            onClick={closeDialog}
            disabled={isSubmitting}
          />
        </DialogFooter>
      </Form>
    </Dialog>
  );
};

export default StaticForm;
