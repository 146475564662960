import { IDataSet } from './models';

export enum FetchDataSetsActionTypes {
  REQUEST = 'datasets.list.FETCH_REQUEST',
  SUCCESS = 'datasets.list.FETCH_SUCCESS',
  FAILURE = 'datasets.list.FETCH_FAILURE',
}

export enum FetchDataSetActionTypes {
  REQUEST = 'datasets.item.FETCH_REQUEST',
  SUCCESS = 'datasets.item.FETCH_SUCCESS',
  FAILURE = 'datasets.item.FETCH_FAILURE',
}

export enum ArchiveDataSetActionTypes {
  REQUEST = 'datasets.item.ARCHIVE_REQUEST',
  SUCCESS = 'datasets.item.ARCHIVE_SUCCESS',
  FAILURE = 'datasets.item.ARCHIVE_FAILURE',
}

export enum UnarchiveDataSetActionTypes {
  REQUEST = 'datasets.item.UNARCHIVE_REQUEST',
  SUCCESS = 'datasets.item.UNARCHIVE_SUCCESS',
  FAILURE = 'datasets.item.UNARCHIVE_FAILURE',
}

type DataSetsMap = {
  [key: string]: IDataSet;
};

export type IDataSetsState = Readonly<{
  byId: Readonly<DataSetsMap>;
  ids: ReadonlyArray<number>;
  isFetching: boolean;
  total: number;
}>;
