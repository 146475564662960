import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Types from 'AppTypes';
import TaskPanelBase from '../components/TaskPanel';
import {
  datasetActions,
  datasetSelectors,
  IDataSet,
  ITask,
  taskActions,
  taskSelectors,
  projectSelectors,
} from '../store';
import { generatePathForRoute } from '../utils/route';
import { googleIntegrationEnabled, yandexIntegrationEnabled } from '../utils/project';

type IStateProps = {
  allowDeleteTask: boolean;
  dataset?: IDataSet;
  task?: ITask;
};

type IDispatchProps = {
  loadDataset: typeof datasetActions.fetchDataSet;
  loadTask: typeof taskActions.fetchTask;
  deleteTask: typeof taskActions.deleteTask;
};

export type IOwnProps = RouteComponentProps<{
  project: string;
  dataset: string;
  task: string;
}>;

const mapStateToProps = (state: Types.RootState, { match: { params } }: IOwnProps): IStateProps => {
  const project = projectSelectors.getProject(state, params.project);

  return {
    dataset: datasetSelectors.getDataSet(state, Number(params.dataset)),
    task: taskSelectors.getTask(state, Number(params.task)),
    allowDeleteTask: googleIntegrationEnabled(project) && yandexIntegrationEnabled(project),
  };
};

const mapDispatchToProps: IDispatchProps = {
  loadDataset: datasetActions.fetchDataSet,
  loadTask: taskActions.fetchTask,
  deleteTask: taskActions.deleteTask,
};

export type ITaskPanelConnectedProps = IStateProps & IDispatchProps & IOwnProps;

export class TaskPanel extends React.Component<ITaskPanelConnectedProps> {
  public componentDidMount(): void {
    const { dataset, loadDataset, loadTask } = this.props;
    const [projectId, datasetId, taskId] = this.getEntitiesIds();

    loadTask(projectId, datasetId, taskId);

    if (dataset?.permissions === undefined) {
      loadDataset(projectId, datasetId);
    }
  }

  private handleClosePanel = () => {
    const {
      history,
      match: { params },
    } = this.props;
    history.push(generatePathForRoute('dataset', params));
  };

  private handleDeleteTask = (task: ITask) => {
    const [projectId, datasetId] = this.getEntitiesIds();
    this.props.deleteTask(projectId, datasetId, task.id);
  };

  private getEntitiesIds(): [string, number, number] {
    const {
      match: { params },
    } = this.props;
    return [params.project, Number(params.dataset), Number(params.task)];
  }

  public render() {
    const { dataset, task, allowDeleteTask } = this.props;

    if (task === undefined || dataset === undefined) {
      return null;
    }

    return (
      <TaskPanelBase
        dataset={dataset}
        task={task}
        allowDeleteTask={allowDeleteTask}
        onClosePanel={this.handleClosePanel}
        onDeleteTask={this.handleDeleteTask}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskPanel);
