import { createSelector } from 'reselect';
import Types from 'AppTypes';

const getDataSetsById = (state: Types.RootState) => state.datasets.byId;
const getDataSetsIds = (state: Types.RootState) => state.datasets.ids;

export const getTotal = (state: Types.RootState): number => state.datasets.total;

export const isFetching = (state: Types.RootState): boolean => state.datasets.isFetching;

export const getDataSets = createSelector(getDataSetsById, getDataSetsIds, (datasets, ids) =>
  ids.map((id) => datasets[id])
);

export const getDataSet = createSelector(
  getDataSetsById,
  (state: Types.RootState, datasetId: number) => datasetId,
  (datasets, id) => datasets[id]
);
