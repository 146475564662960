import { FormikBag, withFormik } from 'formik';
import isEqual from 'lodash/isEqual';
import * as api from '../../../api';
import { IUpdateDataSet } from '../../../api';
import EditDataSetForm from './Form';
import { IFormValues, IGenericFormProps } from './types';
import { StaticReportOptions } from '../../../store';
import { handleError } from '../../../utils/formik';
import { createStaticSchema } from '../utils/validation';

export async function handleSubmit(
  values: IFormValues,
  formBag: FormikBag<IGenericFormProps, IFormValues>
) {
  const {
    setStatus,
    props: { projectId, dataset, onUpdated },
  } = formBag;

  try {
    const reportOptions = dataset.reportOptions || {
      includeVat: false,
      utmSource: '',
      utmMedium: '',
      utmCampaign: '',
      utmTerm: '',
    };
    const payload: IUpdateDataSet = {};

    if (dataset.name !== values.name) {
      payload['name'] = values.name;
    }

    let newReportOptions: StaticReportOptions = {
      includeVat: values.includeVat,
      utmSource: values.utmSource,
      utmMedium: values.utmMedium,
      utmCampaign: values.utmCampaign,
      utmTerm: values.utmTerm,
    };

    if (dataset.reportType === 'by_content') {
      newReportOptions.utmContent = values.utmContent;
    }

    if (!isEqual(reportOptions, newReportOptions)) {
      payload['reportOptions'] = newReportOptions;
    }

    if (Object.keys(payload).length > 0) {
      const response = await api.updateDataSet(projectId, dataset.id, payload);

      setStatus(null);
      onUpdated(response.entities.datasets[dataset.id]);
    } else {
      setStatus(null);
      onUpdated(dataset);
    }
  } catch (e) {
    handleError(e, values, formBag);
  }
}

export default withFormik<IGenericFormProps, IFormValues>({
  handleSubmit,
  validationSchema: createStaticSchema,
  validateOnBlur: false,
  mapPropsToValues: ({ dataset }) => {
    const { name, reportOptions } = dataset;
    let values: IFormValues = {
      name,
      includeVat: !!(undefined !== reportOptions && reportOptions.includeVat),
      utmSource: (reportOptions as StaticReportOptions).utmSource,
      utmMedium: (reportOptions as StaticReportOptions).utmMedium,
      utmCampaign: (reportOptions as StaticReportOptions).utmCampaign,
      utmTerm: (reportOptions as StaticReportOptions).utmTerm,
    };

    if (typeof (reportOptions as StaticReportOptions).utmContent === 'string') {
      values.utmContent = (reportOptions as StaticReportOptions).utmContent as string;
    }

    return values;
  },
})(EditDataSetForm);
