import React from 'react';
import { Switch } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { useToggle } from 'react-use';
import EnhancedRoute from '../../components/EnhancedRoute';
import Page from '../../connected/ProjectAwarePage';
import NewPeriodForm from '../../connected/NewPeriodFormDialog';
import TaskList from '../../connected/TaskList';
import { IRouteProps } from '../../routes';
import { IDataSet, ITask } from '../../store';
import DataSetPanel from '../../connected/DataSetPanel';
import { ICommandBarItemProps } from '../../components/Page';
import { createMenuItems } from '../../utils/dataset';
import EditDataSetForm from '../../connected/EditDataSetFormDialog';

export type ITaskMgmtPageProps = RouteComponentProps<{
  project: string;
  dataset: string;
}> & {
  routes: IRouteProps[];
  dataset?: IDataSet;
  onArchiveDataset: (dataset: IDataSet) => void;
  onUnarchiveDataset: (dataset: IDataSet) => void;
};

export const TaskMgmtPage: React.FC<ITaskMgmtPageProps> = ({
  location,
  history,
  match,
  routes,
  dataset,
  onArchiveDataset,
  onUnarchiveDataset,
}) => {
  const projectId = match.params.project;
  const datasetId = Number(match.params.dataset);

  const selectTask = React.useCallback(
    (task: ITask) => {
      history.push(`${location.pathname}/task/${task.id}`);
    },
    [history, location]
  );

  const [addPeriodDialogOpened, toggleAddPeriodDialog] = useToggle(false);
  const [editDatasetDialogOpened, toggleEditDatasetDialog] = useToggle(false);
  const [datasetPanelOpened, toggleDatasetPanel] = useToggle(false);

  const commandBarFarItems: ICommandBarItemProps[] = [];

  if (dataset?.permissions !== undefined) {
    const datasetMenuItems = createMenuItems(
      dataset,
      onArchiveDataset,
      onUnarchiveDataset,
      toggleEditDatasetDialog
    );
    datasetMenuItems.push({
      key: 'details',
      name: 'О наборе',
      ariaLabel: 'Информация о наборе',
      iconProps: {
        iconName: 'Info',
      },
      onClick: () => toggleDatasetPanel(),
    });

    commandBarFarItems.push({
      key: 'dataset',
      text: 'Набор данных',
      ariaLabel: 'Управление набором данных',
      subMenuProps: {
        items: datasetMenuItems,
      },
    });
  }

  return (
    <Page
      projectId={projectId}
      commandBarItems={[
        {
          key: 'new',
          name: 'Добавить',
          iconProps: {
            iconName: 'Add',
          },
          ariaLabel: 'Добавить период для импорта расходов',
          onClick: toggleAddPeriodDialog,
          disabled: !dataset?.permissions?.edit,
          requireEnabledIntegrations: true,
        },
      ]}
      commandBarFarItems={commandBarFarItems}
    >
      {addPeriodDialogOpened && (
        <NewPeriodForm
          projectId={projectId}
          datasetId={datasetId}
          onCloseDialog={toggleAddPeriodDialog}
          onAdded={toggleAddPeriodDialog}
        />
      )}
      {editDatasetDialogOpened && dataset?.id !== undefined && (
        <EditDataSetForm
          projectId={projectId}
          datasetId={dataset.id}
          onUpdated={toggleEditDatasetDialog}
          onCloseDialog={toggleEditDatasetDialog}
        />
      )}
      {datasetPanelOpened && dataset !== undefined && (
        <DataSetPanel projectId={projectId} dataset={dataset} onClose={toggleDatasetPanel} />
      )}
      <Switch>
        {routes.map((props, index) => (
          <EnhancedRoute key={index} {...props} />
        ))}
      </Switch>
      <TaskList projectId={projectId} datasetId={datasetId} onSelectTask={selectTask} />
    </Page>
  );
};

export default TaskMgmtPage;
