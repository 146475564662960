import React from 'react';
import { MessageBar, MessageBarType, Spinner, SpinnerSize } from 'office-ui-fabric-react';

type IPrepareStateProps = {
  isFetching?: boolean;
};

export const PrepareState: React.FC<IPrepareStateProps> = ({ isFetching }) => {
  if (isFetching) {
    return <Spinner size={SpinnerSize.large} label="Загрузка данных..." />;
  }
  return <MessageBar messageBarType={MessageBarType.error}>Не удалось загрузить данные</MessageBar>;
};

export default PrepareState;
