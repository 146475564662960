import { matchPath, generatePath, match as IMatch } from 'react-router-dom';
import { IBreadcrumbItem } from 'office-ui-fabric-react';
import routes from '../routes';

export interface IBreadcrumbEntityItem extends IBreadcrumbItem {
  entity?: {
    type: 'project' | 'dataset' | 'task';
    value: string;
  };
}

export function buildBreadcrumbItems(path: string): IBreadcrumbEntityItem[] {
  let items: IBreadcrumbEntityItem[] = [];
  let level = 1;

  const getLevel = () => level;

  while (true) {
    const route = routes.find(
      (route) =>
        route.breadcrumb &&
        route.breadcrumb.level === getLevel() &&
        typeof route.path === 'string' &&
        matchPath(path, route.path)
    );

    if (route === undefined) {
      break;
    }

    const match = matchPath(path, route.path as string) as IMatch;

    items.push({
      key: route.breadcrumb!.key,
      text: route.breadcrumb!.title || '',
      entity: route.breadcrumb!.param
        ? {
            type: route.breadcrumb!.param,
            value: (match.params as any)[route.breadcrumb!.param],
          }
        : undefined,
      href: match.isExact ? undefined : generatePath(match.path, match.params),
      isCurrentItem: match.isExact,
    });
    level += 1;
  }

  return items;
}
