import React from 'react';
import { connect } from 'react-redux';
import Types from 'AppTypes';
import { getId } from 'office-ui-fabric-react';
import Page, { IPageProps } from '../components/Page';
import ForbiddenPage from '../pages/ForbiddenPage';
import NotFoundPage from '../pages/NotFoundPage';
import { viewActions, viewSelectors } from '../store';
import { PageStatus } from '../store/view/types';

type IDispatchProps = {
  setPageId: typeof viewActions.setPageId;
};

type IStateProps = {
  currentPageId: string | null;
  pageStatus: PageStatus | null;
};

export type IPageControllerProps = IDispatchProps & IStateProps & IPageProps;

type IPageControllerState = {
  pageId?: string;
};

const mapStateToProps = (state: Types.RootState): IStateProps => ({
  currentPageId: viewSelectors.getPageId(state),
  pageStatus: viewSelectors.getPageStatus(state),
});

const mapDispatchToProps: IDispatchProps = {
  setPageId: viewActions.setPageId,
};

export class PageController extends React.Component<IPageControllerProps, IPageControllerState> {
  state: IPageControllerState = {
    pageId: undefined,
  };

  public componentDidMount(): void {
    const pageId = getId('page-');
    this.setState(
      {
        pageId,
      },
      () => {
        this.props.setPageId({
          pageId,
        });
      }
    );
  }

  public render() {
    const { currentPageId, pageStatus, ...rest } = this.props;

    if (currentPageId === this.state.pageId) {
      if (pageStatus === '404') {
        return <NotFoundPage />;
      }
      if (pageStatus === '403') {
        return <ForbiddenPage />;
      }
    }

    return <Page {...rest} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageController);
