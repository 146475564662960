import { buildUrl } from '@airmedia/api-utils';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { schema } from 'normalizr';
import { httpDelete, httpGet, httpPatch, httpPost, httpPut } from '../../utils/restApi';
import { capitalizeFirstLetter } from '../../utils/string';
import {
  CriteriaArchivedStateEnum,
  DataSetId,
  DataSetType,
  IDataSetDetailsResponse,
  IDataSetListResponse,
  INewDataSet,
  IUpdateDataSet,
  StandardReportOptions,
} from './types';

export * from './types';

const datasetSchema = new schema.Entity(
  'datasets',
  {},
  {
    processStrategy: (entity) => {
      const normalizedEntity = camelizeKeys(entity) as any;

      if (normalizedEntity.type && typeof normalizedEntity.type === 'string') {
        const enumKey = capitalizeFirstLetter(normalizedEntity.type) as keyof typeof DataSetType;
        normalizedEntity.type = DataSetType[enumKey] || DataSetType.Unknown;
      }

      return normalizedEntity;
    },
  }
);

const userSchema = new schema.Entity(
  'users',
  {},
  {
    processStrategy: camelizeKeys,
  }
);

const itemResponseSchema = new schema.Object({
  data: datasetSchema,
  included: {
    users: new schema.Array(userSchema),
  },
});

export async function fetchDataSets(
  projectId: string,
  limit: number,
  offset: number,
  archivedState: CriteriaArchivedStateEnum
): Promise<IDataSetListResponse> {
  const endpoint = buildUrl(`/api/${projectId}/datasets`, {
    limit: limit.toString(),
    offset: offset.toString(),
    archived_state: archivedState,
  });
  const result = await httpGet(endpoint, {
    schema: [datasetSchema],
  });

  if (!result.payload) {
    throw new Error('Unknown result from API method');
  }

  return result.payload as IDataSetListResponse;
}

export async function fetchDataSetById(
  projectId: string,
  datasetId: DataSetId
): Promise<IDataSetDetailsResponse> {
  const result = await httpGet(`/api/${projectId}/datasets/${datasetId}`, {
    respSchema: itemResponseSchema,
  });

  if (!result.payload) {
    throw new Error('Unknown result from API method');
  }

  return result.payload as IDataSetDetailsResponse;
}

export async function createDataSet(
  projectId: string,
  payload: INewDataSet
): Promise<IDataSetDetailsResponse> {
  const resourceType =
    undefined === payload.reportOptions ||
    undefined !== (payload.reportOptions as StandardReportOptions).allowedDomains
      ? 'standard'
      : 'static';
  const result = await httpPost(`/api/${projectId}/datasets/${resourceType}`, {
    data: decamelizeKeys(payload),
    respSchema: itemResponseSchema,
  });

  if (!result.payload) {
    throw new Error('Unknown result from API method');
  }

  return result.payload as IDataSetDetailsResponse;
}

export async function updateDataSet(
  projectId: string,
  datasetId: DataSetId,
  payload: IUpdateDataSet
): Promise<IDataSetDetailsResponse> {
  const result = await httpPatch(`/api/${projectId}/datasets/${datasetId}`, {
    data: decamelizeKeys(payload),
    respSchema: itemResponseSchema,
  });

  if (!result.payload) {
    throw new Error('Unknown result from API method');
  }

  return result.payload as IDataSetDetailsResponse;
}

export async function archiveDataSet(projectId: string, datasetId: DataSetId): Promise<void> {
  const result = await httpPut(`/api/${projectId}/datasets/${datasetId}/archive`);

  if (result.response.status !== 204) {
    throw new Error('Unknown result from API method');
  }
}

export async function unarchiveDataSet(projectId: string, datasetId: DataSetId): Promise<void> {
  const result = await httpDelete(`/api/${projectId}/datasets/${datasetId}/archive`);

  if (result.response.status !== 204) {
    throw new Error('Unknown result from API method');
  }
}
