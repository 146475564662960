import { createSelector } from 'reselect';
import Types from 'AppTypes';

const getTasksById = (state: Types.RootState) => state.tasks.byId;
const getTasksIds = (state: Types.RootState) => state.tasks.ids;

export const getTotal = (state: Types.RootState): number => state.tasks.total;
export const getNextPageOffset = (state: Types.RootState): number => state.tasks.nextPageOffset;
export const isFetching = (state: Types.RootState): boolean => state.tasks.isFetching;

export const getTasks = createSelector(getTasksById, getTasksIds, (byIds, ids) =>
  ids.map((id) => byIds[id])
);

export const getTask = createSelector(
  getTasksById,
  (state: Types.RootState, taskId: number) => taskId,
  (byIds, id) => byIds[id]
);
