import React from 'react';
import { Field, Form, FormikProps } from 'formik';
import {
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  IStackTokens,
  IStackStyles,
} from 'office-ui-fabric-react';
import { FormikTextField } from 'formik-office-ui-fabric-react';
import { IFormValues } from './types';

const stackStyles: IStackStyles = {
  root: {
    width: '100%',
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 15,
};

const SignInForm = ({ isSubmitting, status }: FormikProps<IFormValues>) => (
  <Stack styles={stackStyles} tokens={stackTokens} as={Form}>
    <Stack.Item>
      <Field name="email" label="E-mail" component={FormikTextField} />
      <Field type="password" name="password" label="Пароль" component={FormikTextField} />
    </Stack.Item>
    <Stack.Item>
      <PrimaryButton type="submit" text="Войти" disabled={isSubmitting} />
    </Stack.Item>
    {status && (
      <Stack.Item>
        <MessageBar messageBarType={MessageBarType.error}>{status}</MessageBar>
      </Stack.Item>
    )}
  </Stack>
);

export default SignInForm;
