import { createAsyncAction, createAction } from 'typesafe-actions';
import { DeleteTaskActionTypes, FetchTaskActionTypes, FetchTasksActionTypes } from './types';
import { ITaskDetailsResponse, ITaskListResponse } from '../../api/tasks';

export const fetchTasks = (projectId: string, datasetId: number, reset: boolean = false) =>
  fetchTasksAsync.request({
    projectId,
    datasetId,
    reset,
  });
export const fetchTask = (projectId: string, datasetId: number, taskId: number) =>
  fetchTaskAsync.request({
    projectId,
    datasetId,
    taskId,
  });
export const deleteTask = (projectId: string, datasetId: number, taskId: number) =>
  deleteTaskAsync.request({
    projectId,
    datasetId,
    taskId,
  });

// Internal actions

export const fetchTasksAsync = createAsyncAction(
  FetchTasksActionTypes.REQUEST,
  FetchTasksActionTypes.SUCCESS,
  FetchTasksActionTypes.FAILURE
)<
  {
    projectId: string;
    datasetId: number;
    reset: boolean;
  },
  ITaskListResponse,
  Error
>();

export const fetchTaskAsync = {
  request: createAction(FetchTaskActionTypes.REQUEST)<{
    projectId: string;
    datasetId: number;
    taskId: number;
  }>(),
  success: createAction(FetchTaskActionTypes.SUCCESS)<
    ITaskDetailsResponse,
    {
      projectId: string;
      datasetId: number;
    }
  >(),
  failure: createAction(FetchTaskActionTypes.FAILURE)<
    Error,
    {
      projectId: string;
      datasetId: number;
      taskId: number;
    }
  >(),
};

export const deleteTaskAsync = {
  request: createAction(DeleteTaskActionTypes.REQUEST)<{
    projectId: string;
    datasetId: number;
    taskId: number;
  }>(),
  success: createAction(DeleteTaskActionTypes.SUCCESS)<
    ITaskDetailsResponse,
    {
      projectId: string;
      datasetId: number;
    }
  >(),
  failure: createAction(DeleteTaskActionTypes.FAILURE)<
    Error,
    {
      projectId: string;
      datasetId: number;
      taskId: number;
    }
  >(),
};
