import React from 'react';
import { getTheme, IButtonStyles, IconButton, IIconProps } from 'office-ui-fabric-react';
import { IDataSet } from '../../store';
import { IDataSetListProps } from './index';
import { createMenuItems } from '../../utils/dataset';

export interface IActionsMenuProps {
  dataset: IDataSet;
  onArchiveItemClick: IDataSetListProps['onArchiveItemClick'];
  onUnarchiveItemClick: IDataSetListProps['onUnarchiveItemClick'];
  onEditItemClick: IDataSetListProps['onEditItemClick'];
}

const { semanticColors } = getTheme();
const menuButtonStyles: IButtonStyles = {
  root: {
    height: '100%',
  },
  rootHovered: {
    backgroundColor: semanticColors.menuItemBackgroundPressed,
  },
  menuIcon: {
    fontSize: 'auto',
  },
};

const menuIconProps: IIconProps = {
  iconName: 'MoreVertical',
};

export const ActionsMenu: React.FC<IActionsMenuProps> = ({
  dataset,
  onArchiveItemClick,
  onUnarchiveItemClick,
  onEditItemClick,
}) => {
  const items = createMenuItems(dataset, onArchiveItemClick, onUnarchiveItemClick, onEditItemClick);

  return (
    <IconButton
      menuIconProps={menuIconProps}
      styles={menuButtonStyles}
      menuProps={{
        items: items,
      }}
    />
  );
};

export default ActionsMenu;
