import React from 'react';
import {
  FontWeights,
  getTheme,
  Icon,
  IStackStyles,
  mergeStyleSets,
  Stack,
} from 'office-ui-fabric-react';

export interface IEmptyStateProps {
  primaryMessage: string;
  secondaryMessage?: string;
}

const theme = getTheme();
const classNames = mergeStyleSets({
  icon: {
    fontSize: '48px',
    color: theme.palette.neutralTertiary,
    padding: 0,
    width: 86,
    height: 86,
    lineHeight: 86,
    textAlign: 'center',
    border: `2px solid ${theme.palette.neutralTertiary}`,
    borderRadius: 99999,
  },
  primaryMessage: {
    fontSize: theme.fonts.medium.fontSize,
    fontWeight: FontWeights.bold,
    color: theme.palette.neutralSecondaryAlt,
    textAlign: 'center',
  },
  secondaryMessage: {
    color: theme.palette.neutralSecondaryAlt,
    textAlign: 'center',
  },
});

const stackStyles: IStackStyles = {
  root: {
    maxWidth: 300,
    margin: '0 auto',
  },
};

export const EmptyState = (props: IEmptyStateProps) => (
  <Stack horizontalAlign="center" styles={stackStyles}>
    <Stack.Item>
      <Icon className={classNames.icon} iconName="BulletedList2" />
    </Stack.Item>
    <Stack.Item>
      <p className={classNames.primaryMessage}>{props.primaryMessage}</p>
      {props.secondaryMessage && (
        <p className={classNames.secondaryMessage}>{props.secondaryMessage}</p>
      )}
    </Stack.Item>
  </Stack>
);
