import { NormalizedSchema } from 'normalizr';
import { ReportOptions } from '../datasets';

export enum TaskStageEnum {
  BUILD = 'BUILD',
  UPLOAD = 'UPLOAD',
  CHECK_STATUS = 'CHECK_STATUS',
  DELETE = 'DELETE',
}

export enum TaskStatusEnum {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  SKIPPED = 'SKIPPED',
}

export enum UploadStatusEnum {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  DELETING = 'DELETING',
  DELETED = 'DELETED',
}

type TaskId = number;
type UploadItem = {
  status: UploadStatusEnum;
  statusUpdatedAt: string;
  errors?: string[] | null;
};
type RestartInfo = {
  enabled: boolean;
  after: string;
};
type StatisticsItem = {
  impressions: number;
  clicks: number;
  cost: number;
};

export interface ITaskItem {
  id: TaskId;
  reportDate: string;
  stage: TaskStageEnum;
  status: TaskStatusEnum;
  statusUpdatedAt: string;
  errCode: string | null;
  restart?: RestartInfo;
  upload: UploadItem | null;
  fetchStats?: StatisticsItem;
  fileStats?: StatisticsItem;
}

export interface ITaskDetails extends ITaskItem {
  reportOptions: ReportOptions;
}

export interface IAddPeriod {
  periodStart: string;
  periodEnd: string;
}

type TaskListEntities = {
  tasks: {
    [key: string]: ITaskItem;
  };
};

type TaskListResult = number[];

type TaskDetailsEntities = {
  tasks: {
    [key: string]: ITaskDetails;
  };
};

type TaskDetailsResult = number;

export type ITaskListResponse = NormalizedSchema<TaskListEntities, TaskListResult> & {
  meta: {
    total: number;
    limit: number;
    offset: number;
  };
};

export type ITaskDetailsResponse = NormalizedSchema<TaskDetailsEntities, TaskDetailsResult>;
