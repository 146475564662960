import { createSelector } from 'reselect';
import Types from 'AppTypes';

const getProjectsById = (state: Types.RootState) => state.projects.byId;
const getProjectIds = (state: Types.RootState) => state.projects.ids;

export const getNextPageOffset = (state: Types.RootState): number => state.projects.nextPageOffset;

export const getTotal = (state: Types.RootState): number => state.projects.total;

export const isFetching = (state: Types.RootState): boolean => state.projects.isFetching;

export const getProjects = createSelector(getProjectsById, getProjectIds, (projects, ids) =>
  ids.map((id: string) => projects[id])
);

export const getProject = createSelector(
  getProjectsById,
  (state: Types.RootState, projectId: string) => projectId,
  (projects, projectId: string) => projects[projectId]
);
