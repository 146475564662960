import React from 'react';
import { connect } from 'react-redux';
import Types from 'AppTypes';
import { datasetActions, datasetSelectors, IDataSet } from '../store';
import DataSetListBase from '../components/DataSetList';
import EditDataSetForm from './EditDataSetFormDialog';

type IStateProps = {
  datasets: IDataSet[];
  isFetching: boolean;
};

type IDispatchProps = {
  loadDataSets: (projectId: string) => void;
  archiveDataSet: typeof datasetActions.archiveDataSet;
  unarchiveDataSet: typeof datasetActions.unarchiveDataSet;
};

type IOwnProps = {
  projectId: string;
};

type IDataSetListConnectedProps = IStateProps & IDispatchProps & IOwnProps;
type IDataSetListConnectedState = {
  editDatasetId?: number;
};

const mapStateToProps = (state: Types.RootState): IStateProps => ({
  datasets: datasetSelectors.getDataSets(state),
  isFetching: datasetSelectors.isFetching(state),
});

const mapDispatchToProps: IDispatchProps = {
  loadDataSets: datasetActions.fetchDataSets,
  archiveDataSet: datasetActions.archiveDataSet,
  unarchiveDataSet: datasetActions.unarchiveDataSet,
};

export class DataSetList extends React.Component<
  IDataSetListConnectedProps,
  IDataSetListConnectedState
> {
  state = {
    editDatasetId: undefined,
  };

  public componentDidMount(): void {
    this.props.loadDataSets(this.props.projectId);
  }

  private handleArchiveItemClick = (item: IDataSet) => {
    const { projectId, archiveDataSet } = this.props;
    archiveDataSet(projectId, item.id);
  };

  private handleUnarchiveItemClick = (item: IDataSet) => {
    const { projectId, unarchiveDataSet } = this.props;
    unarchiveDataSet(projectId, item.id);
  };

  private handleEditItemClick = (item: IDataSet) => {
    this.setState({
      editDatasetId: item.id,
    });
  };

  private closeEditItemForm = () => {
    this.setState({
      editDatasetId: undefined,
    });
  };

  public render() {
    const { datasets, isFetching, projectId } = this.props;
    const { editDatasetId } = this.state;

    return (
      <>
        <DataSetListBase
          datasets={datasets}
          isFetching={isFetching}
          projectId={projectId}
          onArchiveItemClick={this.handleArchiveItemClick}
          onUnarchiveItemClick={this.handleUnarchiveItemClick}
          onEditItemClick={this.handleEditItemClick}
        />
        {editDatasetId && (
          <EditDataSetForm
            projectId={projectId}
            datasetId={Number(editDatasetId)}
            onUpdated={this.closeEditItemForm}
            onCloseDialog={this.closeEditItemForm}
          />
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataSetList);
