import React from 'react';
import { Field, Form } from 'formik';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  IStackTokens,
  Label,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
} from 'office-ui-fabric-react';
import { FormikCheckbox, FormikTextField } from 'formik-office-ui-fabric-react';
import DomainFilter from '../../DomainFilter';
import { InfoTooltip } from '../../InfoTooltip';
import { IStandardFormProps } from './types';
import { translate } from '../../../utils/reportType';

const stackTokens: IStackTokens = {
  childrenGap: 15,
};

const checkboxGroupTokens: IStackTokens = {
  childrenGap: 10,
};

export const StandardForm: React.FC<IStandardFormProps> = ({
  isSubmitting,
  status,
  resetForm,
  onCloseDialog,
  values,
  setFieldValue,
  dataset,
}) => {
  const closeDialog = React.useCallback(() => {
    resetForm();
    onCloseDialog();
  }, [resetForm, onCloseDialog]);
  const handleChangeDomainFilter = React.useCallback(
    (value: string[]) => {
      setFieldValue('allowedDomains', value);
    },
    [setFieldValue]
  );

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: 'Редактирование',
        subText: `Импорт данных: ${dataset.name}`,
        showCloseButton: true,
      }}
      modalProps={{
        isBlocking: true,
      }}
      minWidth={480}
      onDismiss={isSubmitting ? undefined : closeDialog}
    >
      <Form>
        <Stack tokens={stackTokens}>
          <Stack.Item>
            <Field
              name="name"
              label="Название"
              required
              disabled={isSubmitting}
              component={FormikTextField}
            />
          </Stack.Item>
          <Stack.Item>
            <Label>Тип отчета</Label>
            <div>{translate(dataset.reportType)}</div>
          </Stack.Item>
          <Stack.Item>
            <Field name="includeVat" label="Расход с учетом НДС" component={FormikCheckbox} />
          </Stack.Item>
          <Stack.Item>
            <DomainFilter
              isSubmitting={isSubmitting}
              values={values.allowedDomains}
              onChange={handleChangeDomainFilter}
            />
          </Stack.Item>
          <Stack.Item>
            <Label>
              Дополнительные типы рекламных кампаний
              <InfoTooltip>
                <>
                  Будет импортирована статистика для всех активных объявлений, независимо
                  от настроек <em>Фильтра по домену</em>.
                </>
              </InfoTooltip>
            </Label>
            <Stack tokens={checkboxGroupTokens}>
              <Field
                name="importDynamicTextAd"
                component={FormikCheckbox}
                label="Импортировать статистику по Динамическим объявлениям"
              />
              <Field
                name="importSmartAd"
                component={FormikCheckbox}
                label="Импортировать статистику по Смарт-баннерам"
              />
            </Stack>
          </Stack.Item>
          {status && <MessageBar messageBarType={MessageBarType.error}>{status}</MessageBar>}
        </Stack>
        <DialogFooter>
          <PrimaryButton type="submit" text="Сохранить" disabled={isSubmitting} />
          <DefaultButton
            type="button"
            text="Отмена"
            onClick={closeDialog}
            disabled={isSubmitting}
          />
        </DialogFooter>
      </Form>
    </Dialog>
  );
};

export default StandardForm;
