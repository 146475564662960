import React from 'react';
import { connect } from 'react-redux';
import Types from 'AppTypes';
import {
  ContextualMenu,
  ContextualMenuItemType,
  IContextualMenuItem,
  memoizeFunction,
  Persona,
  IPersonaStyles,
} from 'office-ui-fabric-react';
import { IProfile, sessionActions, sessionSelectors } from '../store';

type IStateProps = {
  profile: IProfile | null;
};

type IDispatchProps = {
  loadProfile: typeof sessionActions.fetchProfile;
  signout: typeof sessionActions.signout;
};

type IProfileMenuProps = IStateProps &
  IDispatchProps & {
    onSignout?: () => void;
  };

type IProfileMenuState = {
  menuOpen: boolean;
};

const mapStateToProps = (state: Types.RootState) => ({
  profile: sessionSelectors.getProfile(state),
});

const mapDispatchToProps: IDispatchProps = {
  loadProfile: sessionActions.fetchProfile,
  signout: sessionActions.signout,
};

const personaStyles: IPersonaStyles = {
  root: {
    cursor: 'pointer',
  },
  details: {},
  optionalText: {},
  primaryText: {},
  secondaryText: {},
  tertiaryText: {},
  textContent: {},
};

export class ProfileMenu extends React.Component<IProfileMenuProps, IProfileMenuState> {
  public state: IProfileMenuState = {
    menuOpen: false,
  };

  private personaElement = React.createRef<HTMLElement>();

  public componentDidMount(): void {
    if (!this.props.profile) {
      this.props.loadProfile();
    }
  }

  public render() {
    const { profile } = this.props;
    const { menuOpen } = this.state;

    return (
      <>
        <span ref={this.personaElement}>
          <Persona
            text={profile ? profile.email : undefined}
            hidePersonaDetails={true}
            onClick={this.toggleMenu}
            styles={personaStyles}
          />
        </span>
        {menuOpen && (
          <ContextualMenu
            id="profile-menu"
            items={this.getMenuItems(profile)}
            target={this.personaElement.current}
            onDismiss={this.onDismissMenu}
          />
        )}
      </>
    );
  }

  private getMenuItems = memoizeFunction((profile?: IProfile | null): IContextualMenuItem[] => [
    {
      key: 'profile',
      itemType: ContextualMenuItemType.Section,
      sectionProps: {
        title: profile ? profile.email : undefined,
        items: [
          {
            key: 'signout',
            text: 'Выйти',
            onClick: this.onSignout,
          },
        ],
      },
    },
  ]);

  private toggleMenu = () => {
    this.setState((prevState) => ({
      menuOpen: !prevState.menuOpen,
    }));
  };

  private onDismissMenu = () => {
    this.setState({
      menuOpen: false,
    });
  };

  private onSignout = () => {
    const { signout, onSignout } = this.props;

    signout();
    if (onSignout) {
      onSignout();
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);
