import React from 'react';
import { Field } from 'formik';
import { FormikChoiceGroup } from 'formik-office-ui-fabric-react';
import { IChoiceGroupOption, mergeStyles } from 'office-ui-fabric-react';
import {
  REPORT_TYPE_BY_CONTENT,
  REPORT_TYPE_BY_TERM,
  UTM_CONTENT_PREFERRED_FORMAT,
} from '../../../constants/report';
import { ReportType } from '../../../store';
import { translate } from '../../../utils/reportType';
import { InfoTooltip } from '../../InfoTooltip';

const choiceOptionWrapperClassName = mergeStyles({
  display: 'flex',
  alignItems: 'center',
});

const reportTypeOptions: IChoiceGroupOption[] = ([
  REPORT_TYPE_BY_TERM,
  REPORT_TYPE_BY_CONTENT,
] as ReportType[]).map((value) => {
  let options: IChoiceGroupOption = {
    key: value,
    text: translate(value),
  };

  if (value === REPORT_TYPE_BY_CONTENT) {
    options.onRenderField = (props?: IChoiceGroupOption, defaultRender?: any) => {
      return (
        <div className={choiceOptionWrapperClassName}>
          {defaultRender!(props)}
          <InfoTooltip>
            <>
              Есть дополнительные требования к параметру utm_content.
              <br />
              Значение должно содержать определенный набор динамических параметров Яндекс.Директа.
              <br />
              Рекомендуемый формат для utm_content:
              <br />
              <br />
              <code>{UTM_CONTENT_PREFERRED_FORMAT}</code>
            </>
          </InfoTooltip>
        </div>
      );
    };
  }

  return options;
});

export type IReportTypeFieldProps = {
  disabled?: boolean;
};

export const ReportTypeField: React.FC<IReportTypeFieldProps> = ({ disabled }) => (
  <Field
    name="reportType"
    label="Тип отчета"
    required
    disabled={disabled}
    options={reportTypeOptions}
    component={FormikChoiceGroup}
  />
);

export default ReportTypeField;
