import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import { isAuth } from '@airmedia/auth';
import { IRouteProps, AUTH_PATH } from '../routes';

export class EnhancedRoute extends React.Component<IRouteProps> {
  private onRenderRoute = (childProps: RouteComponentProps) => {
    const Component = this.props.component as any;

    return <Component {...childProps} routes={this.props.routes} />;
  };

  public render(): React.ReactNode {
    const { guarded, location, exact, path, strict } = this.props;

    if (guarded && !isAuth()) {
      return (
        <Redirect
          to={{
            pathname: AUTH_PATH,
            state: { from: location },
          }}
        />
      );
    }

    return <Route exact={exact} path={path} strict={strict} render={this.onRenderRoute} />;
  }
}

export default EnhancedRoute;
