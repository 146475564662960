import { NormalizedSchema } from 'normalizr';

export enum CriteriaArchivedStateEnum {
  ALL = 'all',
  ARCHIVED = 'archived',
  NOT_ARCHIVED = 'not_archived',
}

export enum DataSetType {
  Unknown = 0,
  Standard = 1,
  Static = 2,
}

export type ReportType = 'by_term' | 'by_content';
export type DataSetId = number;

export type StandardReportOptions = {
  allowedDomains: string[] | null;
  includeVat: boolean;
  dynamicTextAdEnabled: boolean;
  smartAdEnabled: boolean;
};

export type StaticReportOptions = {
  includeVat: boolean;
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  utmTerm: string;
  utmContent?: string | null;
};

export type ReportOptions = StandardReportOptions | StaticReportOptions;

export interface IDataSetItem {
  readonly id: number;
  readonly name: string;
  readonly type: DataSetType;
  readonly archived: boolean;
  readonly reportType: ReportType;
  readonly createdAt: string;
  readonly permissions: {
    readonly archive: boolean;
    readonly unarchive: boolean;
    readonly edit: boolean;
  };
}

export interface IDataSetDetails extends IDataSetItem {
  readonly dataSource: {
    readonly id: string;
    readonly name: string;
  };
  readonly reportOptions: Readonly<ReportOptions>;
  readonly createdBy: string;
  readonly updatedAt: string;
}

export interface INewDataSet {
  name: string;
  reportType: ReportType;
  dataSource: string;
  reportOptions?: ReportOptions;
}

export interface IUpdateDataSet {
  name?: string;
  reportOptions?: ReportOptions;
}

interface IUserItem {
  readonly id: string;
  readonly email: string;
}

type DataSetListEntities = {
  datasets: {
    [key: string]: IDataSetItem;
  };
};

type DataSetListResult = number[];

type DataSetDetailsEntities = {
  datasets: {
    [key: string]: IDataSetDetails;
  };
  users: {
    [key: string]: IUserItem;
  };
};

type DataSetDetailsResult = {
  data: number;
  included: {
    users: ReadonlyArray<string>;
  };
};

export type IDataSetListResponse = NormalizedSchema<DataSetListEntities, DataSetListResult> & {
  readonly meta: {
    readonly total: number;
    readonly limit: number;
    readonly offset: number;
  };
};

export type IDataSetDetailsResponse = NormalizedSchema<
  DataSetDetailsEntities,
  DataSetDetailsResult
>;
