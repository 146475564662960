import React from 'react';
import { connect } from 'react-redux';
import Types from 'AppTypes';
import TaskMgmtPage, { ITaskMgmtPageProps } from '../pages/TaskMgmtPage';
import { datasetActions, datasetSelectors, IDataSet } from '../store/datasets';

type IDispatchProps = {
  loadDataset: typeof datasetActions.fetchDataSet;
  archiveDataset: typeof datasetActions.archiveDataSet;
  unarchiveDataset: typeof datasetActions.unarchiveDataSet;
};

type IStateProps = Pick<ITaskMgmtPageProps, 'dataset'>;

const mapStateToProps = (
  state: Types.RootState,
  { match: { params } }: ITaskMgmtPageProps
): IStateProps => ({
  dataset: datasetSelectors.getDataSet(state, Number(params.dataset)),
});

const mapDispatchToProps: IDispatchProps = {
  loadDataset: datasetActions.fetchDataSet,
  archiveDataset: datasetActions.archiveDataSet,
  unarchiveDataset: datasetActions.unarchiveDataSet,
};

export class TaskMgmtPageWrapper extends React.Component<ITaskMgmtPageProps & IDispatchProps> {
  public componentDidMount(): void {
    const {
      dataset,
      loadDataset,
      match: { params },
    } = this.props;

    if (dataset === undefined) {
      loadDataset(params.project, Number(params.dataset));
    }
  }

  private handleArchiveDataset = (dataset: IDataSet) => {
    const {
      archiveDataset,
      match: { params },
    } = this.props;

    archiveDataset(params.project, dataset.id);
  };

  private handleUnarchiveDataset = (dataset: IDataSet) => {
    const {
      unarchiveDataset,
      match: { params },
    } = this.props;

    unarchiveDataset(params.project, dataset.id);
  };

  public render() {
    return (
      <TaskMgmtPage
        {...this.props}
        onArchiveDataset={this.handleArchiveDataset}
        onUnarchiveDataset={this.handleUnarchiveDataset}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskMgmtPageWrapper);
