import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IContextualMenuItem } from 'office-ui-fabric-react';
import Page from '../../connected/ProjectAwarePage';
import DataSetList from '../../connected/DataSetList';
import NewDataSetForm from '../../connected/NewDataSetFormDialog';
import { DataSetType } from '../../store';

export type IDataSetMgmtPageProps = RouteComponentProps<{
  project: string;
}>;

export const DataSetMgmtPage: React.FC<IDataSetMgmtPageProps> = ({ match }) => {
  const projectId = match.params.project;
  const [newFormDialogState, setNewFormDialogState] = React.useState({
    open: false,
    type: DataSetType.Unknown,
  });
  const onCreateDatasetClick = React.useCallback(
    (
      ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
      item?: IContextualMenuItem
    ) => {
      if (item !== undefined) {
        setNewFormDialogState({
          open: true,
          type: item.data,
        });
      }
    },
    [setNewFormDialogState]
  );
  const closeNewFormDialog = React.useCallback(() => {
    setNewFormDialogState({
      open: false,
      type: DataSetType.Unknown,
    });
  }, [setNewFormDialogState]);

  return (
    <Page
      projectId={projectId}
      commandBarItems={[
        {
          key: 'new',
          text: 'Создать',
          iconProps: {
            iconName: 'Add',
          },
          ariaLabel: 'Создать новый импорт данных',
          requireEnabledIntegrations: true,
          subMenuProps: {
            items: [
              {
                key: 'newStandardDataset',
                text: 'Базовый',
                secondaryText:
                  'Текстово-графические кампании, Медийные кампании, Смарт-баннеры, Динамические объявления',
                title: 'Разметка происходит согласно utm-параметрам в ссылках объявлений.',
                ariaLabel: 'Создать базовый импорт данных',
                iconProps: {
                  iconName: 'DataflowsLink',
                },
                data: DataSetType.Standard,
                onClick: onCreateDatasetClick,
              },
              {
                key: 'newStaticDataset',
                text: 'Статический',
                secondaryText: 'Баннер на поиске',
                title: 'Разметка происходит согласно заданным вручную utm-параметрам.',
                ariaLabel: 'Создать статический импорт данных',
                iconProps: {
                  iconName: 'BullseyeTargetEdit',
                },
                data: DataSetType.Static,
                onClick: onCreateDatasetClick,
              },
            ],
          },
        },
      ]}
    >
      {newFormDialogState.open && (
        <NewDataSetForm
          projectId={projectId}
          datasetType={newFormDialogState.type}
          onCloseDialog={closeNewFormDialog}
          onCreated={closeNewFormDialog}
        />
      )}
      <DataSetList projectId={projectId} />
    </Page>
  );
};

export default DataSetMgmtPage;
