import React from 'react';
import { Field, Form, FormikProps } from 'formik';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  IStackTokens,
  Label,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
} from 'office-ui-fabric-react';
import { FormikCheckbox, FormikTextField } from 'formik-office-ui-fabric-react';
import { IGenericFormProps, IStandardFormValues } from './types';
import DomainFilter from '../../DomainFilter';
import { InfoTooltip } from '../../InfoTooltip';
import DataSourceField from '../Field/DataSourceField';
import ReportTypeField from '../Field/ReportTypeField';

const stackTokens: IStackTokens = {
  childrenGap: 15,
};

const checkboxGroupTokens: IStackTokens = {
  childrenGap: 10,
};

type IStandardFormProps = IGenericFormProps & FormikProps<IStandardFormValues>;

export const StandardForm: React.FC<IStandardFormProps> = ({
  isSubmitting,
  status,
  onCloseDialog,
  dataSources,
  values,
  setFieldValue,
}) => {
  const handleChangeDomainFilter = React.useCallback(
    (value: string[]) => {
      setFieldValue('allowedDomains', value);
    },
    [setFieldValue]
  );

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: 'Новый импорт данных',
        subText:
          'Разметка происходит согласно заданным utm-параметрам в ссылках объявлений. Совместимо со следующими типами рекламных кампаний: Текстово-графические объявления, Медийная кампания, Смарт-баннеры и Динамические объявления.',
        showCloseButton: true,
      }}
      modalProps={{
        isBlocking: true,
      }}
      minWidth={600}
      onDismiss={isSubmitting ? undefined : onCloseDialog}
    >
      <Form>
        <Stack tokens={stackTokens}>
          <Stack.Item>
            <Field
              name="name"
              label="Название"
              required
              disabled={isSubmitting}
              component={FormikTextField}
            />
          </Stack.Item>
          <Stack.Item>
            <ReportTypeField />
          </Stack.Item>
          <Stack.Item>
            <DataSourceField dataSources={dataSources} reportType={values.reportType} />
          </Stack.Item>
          <Stack.Item>
            <Field name="includeVat" component={FormikCheckbox} label="Расход с учетом НДС" />
          </Stack.Item>
          <Stack.Item>
            <DomainFilter
              isSubmitting={isSubmitting}
              values={values.allowedDomains}
              onChange={handleChangeDomainFilter}
            />
          </Stack.Item>
          <Stack.Item>
            <Label>
              Дополнительные типы рекламных кампаний
              <InfoTooltip>
                <>
                  Будет импортирована статистика для всех активных объявлений, независимо
                  от настроек <em>Фильтра по домену</em>.
                </>
              </InfoTooltip>
            </Label>
            <Stack tokens={checkboxGroupTokens}>
              <Field
                name="importDynamicTextAd"
                component={FormikCheckbox}
                label="Импортировать статистику по Динамическим объявлениям"
              />
              <Field
                name="importSmartAd"
                component={FormikCheckbox}
                label="Импортировать статистику по Смарт-баннерам"
              />
            </Stack>
          </Stack.Item>
          {status && <MessageBar messageBarType={MessageBarType.error}>{status}</MessageBar>}
        </Stack>
        <DialogFooter>
          <PrimaryButton type="submit" text="Сохранить" disabled={isSubmitting} />
          <DefaultButton
            type="button"
            text="Отмена"
            onClick={onCloseDialog}
            disabled={isSubmitting}
          />
        </DialogFooter>
      </Form>
    </Dialog>
  );
};

export default StandardForm;
