import { createAction } from 'typesafe-actions';
import { PageStatus } from './types';

export const setPageId = createAction('view.SET_PAGE_ID')<{
  pageId: string;
}>();

export const setPageStatus = createAction('view.SET_PAGE_STATUS')<{
  status: PageStatus;
  rewrite: boolean;
}>();
