import { IProject } from './models';

export enum FetchProjectsActionTypes {
  REQUEST = 'projects.list.FETCH_REQUEST',
  SUCCESS = 'projects.list.FETCH_SUCCESS',
  FAILURE = 'projects.list.FETCH_FAILURE',
}

export enum FetchProjectDetailsActionTypes {
  REQUEST = 'projects.item.FETCH_REQUEST',
  SUCCESS = 'projects.item.FETCH_SUCCESS',
  FAILURE = 'projects.item.FETCH_FAILURE',
}

type ProjectsMap = {
  [key: string]: IProject;
};

export type IProjectsState = Readonly<{
  byId: Readonly<ProjectsMap>;
  ids: ReadonlyArray<string>;
  isFetching: boolean;
  nextPageOffset: number;
  total: number;
}>;
