import React from 'react';
import { ITheme, Stack } from 'office-ui-fabric-react';
import rollbar from '../rollbar';

export interface IErrorHandlerState {
  error?: Error;
  errorInfo?: React.ErrorInfo;
}

type IErrorHandlerProps = {
  children: React.ReactElement;
};

const stackStyles = (props: any, theme: ITheme) => ({
  root: {
    width: 960,
    margin: '0 auto',
    color: theme.semanticColors.errorText,
  },
});

export class MainErrorHandler extends React.Component<IErrorHandlerProps, IErrorHandlerState> {
  public state: IErrorHandlerState = {
    error: undefined,
    errorInfo: undefined,
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    });

    rollbar.critical('Error while rendering components', error, {
      errorInfo,
    });
  }

  public render(): JSX.Element {
    if (this.state.error) {
      return (
        <Stack
          horizontalAlign="center"
          verticalAlign="center"
          verticalFill
          tokens={{
            childrenGap: 15,
          }}
          styles={stackStyles}
        >
          <h1>Oops!</h1>
          <p>Мы пытались нарисовать красивую страницу, но что-то пошло не так.</p>
          <p>Если ошибка повторится, обратитесь, пожалуйста, в поддержку сервиса.</p>
        </Stack>
      );
    }

    return this.props.children;
  }
}
