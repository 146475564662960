import React from 'react';
import { UploadStatusEnum } from '../store';

export interface IUploadStatusProps {
  status: UploadStatusEnum;
  className?: string;
}

const uploadStatusMap = {
  [UploadStatusEnum.PENDING]: 'Файл загружен в GA и обрабатывается...',
  [UploadStatusEnum.COMPLETED]: 'Файл загружен в GA и обработан',
  [UploadStatusEnum.FAILED]: 'Файл загружен в GA, но есть ошибки',
  [UploadStatusEnum.DELETING]: 'Файл удаляется...',
  [UploadStatusEnum.DELETED]: 'Файл удалён из GA',
};

export const UploadStatus: React.FC<IUploadStatusProps> = ({ className, status }) => (
  <span className={className}>{uploadStatusMap[status] || status}</span>
);

export default UploadStatus;
