import React from 'react';
import Page, { IPageStyles } from '../../components/Page';
import forbiddenImg from '../../assets/forbidden.svg';

const pageStyles: IPageStyles = {
  main: {
    margin: '0 auto',
    maxWidth: 960,
    minHeight: 'calc(100vh - 156px)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '480px',
    backgroundPosition: '80% 80%',
    backgroundImage: `url(${forbiddenImg})`,
  },
};

export const ForbiddenPage = () => (
  <Page styles={pageStyles}>
    <h1>Ошибка доступа</h1>
    <p>К сожалению, у Вас недостаточно прав для работы с данным ресурсом.</p>
  </Page>
);

export default ForbiddenPage;
