import { FormikBag, withFormik } from 'formik';
import * as api from '../../../api';
import { IDataSetDetailsResponse } from '../../../api';
import StandardForm from './Form';
import { IGenericFormProps, IStandardFormValues } from './types';
import { handleError } from '../../../utils/formik';
import { createStandardSchema } from '../utils/validation';

export async function handleSubmit(
  values: IStandardFormValues,
  formBag: FormikBag<IGenericFormProps, IStandardFormValues>
) {
  const {
    setStatus,
    props: { onCreated, projectId },
  } = formBag;
  try {
    const response: IDataSetDetailsResponse = await api.createDataSet(projectId, {
      name: values.name,
      dataSource: values.dataSource,
      reportType: values.reportType,
      reportOptions: {
        allowedDomains: values.allowedDomains.length > 0 ? values.allowedDomains : null,
        includeVat: values.includeVat,
        dynamicTextAdEnabled: values.importDynamicTextAd,
        smartAdEnabled: values.importSmartAd,
      },
    });
    setStatus(null);
    onCreated(response.entities.datasets[response.result.data]);
  } catch (e) {
    handleError(e, values, formBag);
  }
}

export default withFormik<IGenericFormProps, IStandardFormValues>({
  handleSubmit,
  validationSchema: createStandardSchema,
  validateOnBlur: false,
  mapPropsToValues: () => ({
    name: '',
    reportType: 'by_term',
    dataSource: '',
    allowedDomains: [],
    includeVat: true,
    importDynamicTextAd: false,
    importSmartAd: false,
  }),
})(StandardForm);
