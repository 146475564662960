import { call, cancel, fork, ForkEffect, take } from 'redux-saga/effects';
import { ActionPattern, Task } from '@redux-saga/types';
import { Action } from 'redux';

type TaskMap = {
  [key: string]: Task;
};

/* eslint-disable redux-saga/no-unhandled-errors */

export function takeLatestPerKey<A extends Action>(
  patternOrChannel: ActionPattern<A>,
  worker: (action: A) => any,
  keySelector: (action: A) => string,
  ...args: any[]
): ForkEffect {
  return fork(function* () {
    const tasks: TaskMap = {};

    while (true) {
      const action = yield take(patternOrChannel);
      const key = yield call(keySelector, action);

      if (tasks[key]) {
        yield cancel(tasks[key]);
      }

      tasks[key] = yield fork(worker as any, ...args, action);
    }
  });
}

export function takeLeadingPerKey<A extends Action>(
  patternOrChannel: ActionPattern<A>,
  worker: (action: A) => any,
  keySelector: (action: A) => string,
  ...args: any[]
): ForkEffect {
  return fork(function* () {
    const tasks: TaskMap = {};

    while (true) {
      const action = yield take(patternOrChannel);
      const key = yield call(keySelector, action);

      if (!tasks[key] || !tasks[key].isRunning()) {
        tasks[key] = yield fork(worker as any, ...args, action);
      }
    }
  });
}

/* eslint-enable redux-saga/no-unhandled-errors */
