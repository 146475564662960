import { buildUrl } from '@airmedia/api-utils';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { schema } from 'normalizr';
import { httpDelete, httpGet, httpPost } from '../../utils/restApi';
import { IAddPeriod, ITaskDetailsResponse, ITaskListResponse } from './types';

export * from './types';

const taskSchema = new schema.Entity(
  'tasks',
  {},
  {
    processStrategy: (entity) => camelizeKeys(entity),
  }
);

export async function fetchTasks(
  projectId: string,
  datasetId: number,
  limit: number,
  offset: number = 0
): Promise<ITaskListResponse> {
  const endpoint = buildUrl(`/api/${projectId}/datasets/${datasetId}/tasks`, {
    limit: limit.toString(),
    offset: offset.toString(),
  });

  const result = await httpGet(endpoint, {
    schema: [taskSchema],
  });

  if (!result.payload) {
    throw new Error('Unknown result from API method');
  }

  return result.payload as ITaskListResponse;
}

export async function fetchTaskById(
  projectId: string,
  datasetId: number,
  taskId: number
): Promise<ITaskDetailsResponse> {
  const result = await httpGet(`/api/${projectId}/datasets/${datasetId}/tasks/${taskId}`, {
    schema: taskSchema,
  });

  if (!result.payload) {
    throw new Error('Unknown result from API method');
  }

  return result.payload as ITaskDetailsResponse;
}

export async function deleteTask(
  projectId: string,
  datasetId: number,
  taskId: number
): Promise<void> {
  await httpDelete(`/api/${projectId}/datasets/${datasetId}/tasks/${taskId}`);
}

export async function addPeriod(
  projectId: string,
  datasetId: number,
  payload: IAddPeriod
): Promise<void> {
  await httpPost(`/api/${projectId}/datasets/${datasetId}/tasks`, {
    data: decamelizeKeys(payload),
  });
}
