import React from 'react';
import { TaskStageEnum, TaskStatusEnum } from '../store';
import { isResolvingUploadStatus } from '../utils/task';

export interface IUploadStatusProps {
  stage: TaskStageEnum;
  status: TaskStatusEnum;
  className?: string;
}

const taskStatusMap = {
  [TaskStatusEnum.PENDING]: 'Ждёт обработки',
  [TaskStatusEnum.RUNNING]: 'Выполняется',
  [TaskStatusEnum.COMPLETED]: 'Завершена',
  [TaskStatusEnum.FAILED]: 'Ошибка',
  [TaskStatusEnum.SKIPPED]: 'Пропущена',
};

export const TaskStatus: React.FC<IUploadStatusProps> = ({ className, stage, status }) => {
  let description = taskStatusMap[status] || status;

  if (isResolvingUploadStatus(stage, status)) {
    description = 'Проверка';
  }

  return <span className={className}>{description}</span>;
};

export default TaskStatus;
