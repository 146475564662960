import React from 'react';
import { connect } from 'react-redux';
import Types from 'AppTypes';
import NewPeriodForm from '../components/NewPeriodForm';
import { taskActions } from '../store';
import { Dispatch } from 'redux';

const mapDispatchToProps = (
  dispatch: Dispatch<Types.RootAction>,
  { projectId, datasetId, onAdded }: React.ComponentProps<typeof NewPeriodForm>
) => ({
  onAdded: () => {
    dispatch(taskActions.fetchTasks(projectId, datasetId, true));
    onAdded();
  },
});

export default connect(null, mapDispatchToProps)(NewPeriodForm);
