import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { takeLeadingPerKey } from '../../utils/saga';
import * as api from '../../api';
import * as actions from './actions';
import { getNextPageOffset } from './selectors';
import * as types from './types';

type LoadTaskListAction = ReturnType<typeof actions.fetchTasks>;
type LoadTaskAction = ReturnType<typeof actions.fetchTask>;
type DeleteTaskAction = ReturnType<typeof actions.deleteTask>;

function* loadTasksList(action: LoadTaskListAction): SagaIterator {
  const { projectId, datasetId, reset } = action.payload;

  try {
    const offset = reset ? 0 : yield select(getNextPageOffset);
    const response = yield call(api.fetchTasks, projectId, datasetId, 30, offset as number);
    yield put(actions.fetchTasksAsync.success(response as api.ITaskListResponse));
  } catch (e) {
    yield put(actions.fetchTasksAsync.failure(e));
  }
}

function* loadTask(action: LoadTaskAction): SagaIterator {
  const { projectId, datasetId, taskId } = action.payload;

  try {
    const response = yield call(api.fetchTaskById, projectId, datasetId, taskId);
    yield put(
      actions.fetchTaskAsync.success(response as api.ITaskDetailsResponse, {
        projectId,
        datasetId,
      })
    );
  } catch (e) {
    yield put(
      actions.fetchTaskAsync.failure(e, {
        projectId,
        datasetId,
        taskId,
      })
    );
  }
}

function* deleteTask(action: DeleteTaskAction): SagaIterator {
  const { projectId, datasetId, taskId } = action.payload;

  try {
    yield call(api.deleteTask, projectId, datasetId, taskId);
    const response = yield call(api.fetchTaskById, projectId, datasetId, taskId);
    yield put(
      actions.deleteTaskAsync.success(response as api.ITaskDetailsResponse, {
        projectId,
        datasetId,
      })
    );
  } catch (e) {
    yield put(
      actions.deleteTaskAsync.failure(e, {
        projectId,
        datasetId,
        taskId,
      })
    );
  }
}

type GenericItemAction = {
  payload: {
    taskId: number;
  };
};

export function* tasksSagas() {
  const selectKey = ({ payload }: GenericItemAction) => payload.taskId.toString();

  // eslint-disable-next-line redux-saga/no-unhandled-errors
  yield all([
    takeLatest(types.FetchTasksActionTypes.REQUEST, loadTasksList),
    takeLeadingPerKey(types.FetchTaskActionTypes.REQUEST, loadTask, selectKey),
    takeLeadingPerKey(types.DeleteTaskActionTypes.REQUEST, deleteTask, selectKey),
  ]);
}
