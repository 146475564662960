import { ApiError, RequestError } from '@airmedia/api-utils';
import { transformApiErrorsToFormErrors } from './form';
import { FormikHelpers } from 'formik';

export function handleError<Values>(
  e: Error,
  formValues: Values,
  { setStatus, setErrors }: FormikHelpers<Values>
): void {
  if (e instanceof RequestError && e.code >= 500) {
    setStatus('Сервис временно недоступен. Пожалуйста, попробуйте повторить запрос позже.');
  } else if (e instanceof ApiError && e.code === 422 && e.payload.errors) {
    setErrors(transformApiErrorsToFormErrors(formValues, e.payload.errors));
    setStatus('Исправьте ошибки и попробуйте отправить данные снова.');
  } else {
    setStatus('При обработке формы возникла неизвестная ошибка.');
  }
}
