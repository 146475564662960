import { FormikBag, withFormik } from 'formik';
import * as api from '../../../api';
import { IDataSetDetailsResponse } from '../../../api';
import StaticForm from './Form';
import { IGenericFormProps, IStaticFormValues } from './types';
import { handleError } from '../../../utils/formik';
import { createStaticSchema } from '../utils/validation';

export async function handleSubmit(
  values: IStaticFormValues,
  formBag: FormikBag<IGenericFormProps, IStaticFormValues>
) {
  const {
    setStatus,
    props: { onCreated, projectId },
  } = formBag;

  try {
    const response: IDataSetDetailsResponse = await api.createDataSet(projectId, {
      name: values.name,
      dataSource: values.dataSource,
      reportType: values.reportType,
      reportOptions: {
        includeVat: values.includeVat,
        utmSource: values.utmSource,
        utmMedium: values.utmMedium,
        utmCampaign: values.utmCampaign,
        utmTerm: values.utmTerm,
        utmContent: values.reportType === 'by_content' ? values.utmContent : undefined,
      },
    });

    setStatus(null);
    onCreated(response.entities.datasets[response.result.data]);
  } catch (e) {
    handleError(e, values, formBag);
  }
}

export default withFormik<IGenericFormProps, IStaticFormValues>({
  handleSubmit,
  validationSchema: createStaticSchema,
  validateOnBlur: false,
  mapPropsToValues: () => ({
    name: '',
    reportType: 'by_term',
    dataSource: '',
    includeVat: true,
    utmSource: '',
    utmMedium: '',
    utmCampaign: '',
    utmTerm: '',
    utmContent: '',
  }),
})(StaticForm);
