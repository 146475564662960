import React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb as BreadcrumbView, IBreadcrumbProps } from 'office-ui-fabric-react';
import Types from 'AppTypes';
import {
  datasetActions,
  datasetSelectors,
  IDataSet,
  IProject,
  ITask,
  projectActions,
  projectSelectors,
  taskActions,
  taskSelectors,
} from '../store';
import { IBreadcrumbEntityItem } from '../utils/breadcrumb';

type IStateProps = {
  projectId?: string;
  datasetId?: number;
  taskId?: number;
  project?: IProject;
  dataset?: IDataSet;
  task?: ITask;
};

type IDispatchProps = {
  loadProject: typeof projectActions.fetchProjectDetails;
  loadDataSet: typeof datasetActions.fetchDataSet;
  loadTask: typeof taskActions.fetchTask;
};

type IOwnProps = IBreadcrumbProps & {
  items: IBreadcrumbEntityItem[];
};

export type IBreadcrumpConnectedProps = IOwnProps & IStateProps & IDispatchProps;

const mapStateToProps = (state: Types.RootState, { items }: IOwnProps): IStateProps => {
  let props: IStateProps = {};

  for (let item of items) {
    if (item.entity === undefined) {
      continue;
    }

    switch (item.entity.type) {
      case 'project':
        props.projectId = item.entity.value;
        props.project = projectSelectors.getProject(state, item.entity.value);
        break;
      case 'dataset':
        props.datasetId = Number(item.entity.value);
        props.dataset = datasetSelectors.getDataSet(state, Number(item.entity.value));
        break;
      case 'task':
        props.taskId = Number(item.entity.value);
        props.task = taskSelectors.getTask(state, Number(item.entity.value));
        break;
    }
  }

  return props;
};

const mapDispatchToProps: IDispatchProps = {
  loadDataSet: datasetActions.fetchDataSet,
  loadProject: projectActions.fetchProjectDetails,
  loadTask: taskActions.fetchTask,
};

function shouldLoadProject(project?: IProject): boolean {
  if (project === undefined) {
    return true;
  }

  if (project.isFetching) {
    return false;
  }

  return project.name === undefined;
}

function shouldLoadDataset(dataset?: IDataSet): boolean {
  if (dataset === undefined) {
    return true;
  }

  if (dataset.isFetching) {
    return false;
  }

  return dataset.name === undefined;
}

function shouldLoadTask(task?: ITask): boolean {
  if (task === undefined) {
    return true;
  }

  if (task.isFetching) {
    return false;
  }

  return task.id === undefined;
}

export class Breadcrumb extends React.Component<IBreadcrumpConnectedProps> {
  public componentDidMount(): void {
    this.loadData();
  }

  public componentDidUpdate(prevProps: Readonly<IBreadcrumpConnectedProps>): void {
    const { projectId, datasetId, taskId } = this.props;

    if (
      projectId !== prevProps.projectId ||
      datasetId !== prevProps.datasetId ||
      taskId !== prevProps.taskId
    ) {
      this.loadData();
    }
  }

  public render() {
    const { projectId, project, datasetId, dataset, taskId, task, items, ...rest } = this.props;
    const normItems = items.map((item: IBreadcrumbEntityItem) => {
      if (item.entity) {
        switch (item.entity.type) {
          case 'project':
            if (project && project.name) {
              return {
                ...item,
                text: project.name,
              };
            }
            break;
          case 'dataset':
            if (dataset && dataset.name) {
              return {
                ...item,
                text: dataset.name,
              };
            }
            break;
          case 'task':
            if (task && task.id) {
              return {
                ...item,
                text: `Задача №${task.id}`,
              };
            }
            break;
        }
      }

      return item;
    });

    return <BreadcrumbView items={normItems} {...rest} />;
  }

  private loadData() {
    const {
      projectId,
      project,
      datasetId,
      dataset,
      taskId,
      task,
      loadProject,
      loadDataSet,
      loadTask,
    } = this.props;

    if (projectId && shouldLoadProject(project)) {
      loadProject(projectId);
    }

    if (projectId && datasetId && shouldLoadDataset(dataset)) {
      loadDataSet(projectId, datasetId);
    }

    if (projectId && datasetId && taskId && shouldLoadTask(task)) {
      loadTask(projectId, datasetId, taskId);
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumb);
