import { RouteProps as BaseRouteProps } from 'react-router-dom';
import AuthPage from './pages/AuthPage';
import DataSetMgmtPage from './pages/DataSetMgmtPage';
import MainPage from './pages/MainPage';
import NotFoundPage from './pages/NotFoundPage';
import TaskMgmtPage from './connected/TaskMgmtPage';
import TaskPanel from './connected/TaskPanel';

export interface IRouteProps extends BaseRouteProps {
  readonly key: string;
  readonly guarded: boolean;
  readonly routes?: IRouteProps[];
  readonly breadcrumb?: {
    level: number;
    key: string;
    title?: string;
    param?: 'project' | 'dataset' | 'task';
  };
}

export const HOME_PATH = '/';
export const AUTH_PATH = '/auth';

const UUID_RE = '[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}';

export const routes: IRouteProps[] = [
  {
    key: 'main',
    path: HOME_PATH,
    exact: true,
    guarded: true,
    component: MainPage,
    breadcrumb: {
      level: 1,
      key: 'projects',
      title: 'Проекты',
    },
  },
  {
    key: 'auth',
    path: AUTH_PATH,
    exact: true,
    guarded: false,
    component: AuthPage,
  },
  {
    key: 'project',
    path: `/:project(${UUID_RE})`,
    exact: true,
    guarded: true,
    component: DataSetMgmtPage,
    breadcrumb: {
      level: 2,
      key: 'project',
      param: 'project',
    },
  },
  {
    key: 'dataset',
    path: `/:project(${UUID_RE})/ds/:dataset([0-9]+)`,
    guarded: true,
    component: TaskMgmtPage,
    breadcrumb: {
      level: 3,
      key: 'dataset',
      param: 'dataset',
    },
    routes: [
      {
        key: 'task',
        path: `/:project(${UUID_RE})/ds/:dataset([0-9]+)/task/:task([0-9]+)`,
        exact: true,
        guarded: true,
        component: TaskPanel,
      },
    ],
  },
  {
    key: 'notFound',
    guarded: false,
    component: NotFoundPage,
  },
];

export default routes;
