import { ITask } from './models';

export enum FetchTasksActionTypes {
  REQUEST = 'tasks.list.FETCH_REQUEST',
  SUCCESS = 'tasks.list.FETCH_SUCCESS',
  FAILURE = 'tasks.list.FETCH_FAILURE',
}

export enum FetchTaskActionTypes {
  REQUEST = 'tasks.item.FETCH_REQUEST',
  SUCCESS = 'tasks.item.FETCH_SUCCESS',
  FAILURE = 'tasks.item.FETCH_FAILURE',
}

export enum DeleteTaskActionTypes {
  REQUEST = 'tasks.item.DELETE_REQUEST',
  SUCCESS = 'tasks.item.DELETE_SUCCESS',
  FAILURE = 'tasks.item.DELETE_FAILURE',
}

type TasksMap = {
  [key: string]: ITask;
};

export type ITasksState = Readonly<{
  byId: Readonly<TasksMap>;
  ids: ReadonlyArray<number>;
  isFetching: boolean;
  total: number;
  nextPageOffset: number;
}>;
