import React from 'react';
import { Field, Form, FormikProps } from 'formik';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  IStackTokens,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
} from 'office-ui-fabric-react';
import { FormikCheckbox, FormikTextField } from 'formik-office-ui-fabric-react';
import { IGenericFormProps, IStaticFormValues } from './types';
import DataSourceField from '../Field/DataSourceField';
import ReportTypeField from '../Field/ReportTypeField';

const stackTokens: IStackTokens = {
  childrenGap: 15,
};

type IStaticFormProps = IGenericFormProps & FormikProps<IStaticFormValues>;

export const StaticForm: React.FC<IStaticFormProps> = ({
  isSubmitting,
  status,
  onCloseDialog,
  dataSources,
  values,
}) => {
  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: 'Новый импорт данных',
        subText:
          'Разметка происходит согласно заданным шаблонам для utm-параметров. Актуально для рекламных кампаний с типом: Баннер на поиске.',
        showCloseButton: true,
      }}
      modalProps={{
        isBlocking: true,
      }}
      minWidth={600}
      onDismiss={isSubmitting ? undefined : onCloseDialog}
    >
      <Form>
        <Stack tokens={stackTokens}>
          <Stack.Item>
            <Field
              name="name"
              label="Название"
              required
              disabled={isSubmitting}
              component={FormikTextField}
            />
          </Stack.Item>
          <Stack.Item>
            <ReportTypeField />
          </Stack.Item>
          <Stack.Item>
            <DataSourceField dataSources={dataSources} reportType={values.reportType} />
          </Stack.Item>
          <Stack.Item>
            <Field name="includeVat" label="Расход с учетом НДС" component={FormikCheckbox} />
          </Stack.Item>
          <Stack.Item>
            <Field
              name="utmSource"
              label="utm_source"
              required
              placeholder="yandex_direct"
              disabled={isSubmitting}
              component={FormikTextField}
            />
          </Stack.Item>
          <Stack.Item>
            <Field
              name="utmMedium"
              label="utm_medium"
              required
              placeholder="cpc"
              disabled={isSubmitting}
              component={FormikTextField}
            />
          </Stack.Item>
          <Stack.Item>
            <Field
              name="utmCampaign"
              label="utm_campaign"
              required
              placeholder="{campaign_id}_{campaign_name}"
              disabled={isSubmitting}
              component={FormikTextField}
            />
          </Stack.Item>
          <Stack.Item>
            <Field
              name="utmTerm"
              label="utm_term"
              required
              placeholder="{phrase_id}_{keyword}"
              disabled={isSubmitting}
              component={FormikTextField}
            />
          </Stack.Item>
          {values.reportType === 'by_content' && (
            <Stack.Item>
              <Field
                name="utmContent"
                label="utm_content"
                required
                placeholder="ct:{campaign_type}|cid:{campaign_id}|gid:{gbid}|ad:{ad_id}|criteria:{phrase_id}|radjid:{coef_goal_context_id}|place:{source}|nt:{source_type}|dev:{device_type}|loc:{region_id}|slot:{position_type}"
                disabled={isSubmitting}
                component={FormikTextField}
              />
            </Stack.Item>
          )}
          {status && <MessageBar messageBarType={MessageBarType.error}>{status}</MessageBar>}
        </Stack>
        <DialogFooter>
          <PrimaryButton type="submit" text="Сохранить" disabled={isSubmitting} />
          <DefaultButton
            type="button"
            text="Отмена"
            onClick={onCloseDialog}
            disabled={isSubmitting}
          />
        </DialogFooter>
      </Form>
    </Dialog>
  );
};

export default StaticForm;
